<template>
  <div id="allrecords" class="t-records" data-hook="blocks-collection-content-node" data-tilda-project-id="5692240"
       data-tilda-page-id="28265571" data-tilda-formskey="081c2bc8009246e60a34701f65692240" data-tilda-lazy="yes">
    <div id="rec501144033" class="r t-rec" style=" " data-animationappear="off" data-record-type="360"><!-- T360 -->


    </div>
    <a-affix :offset-top="top">
      <div id="rec472955815" class="r t-rec t-screenmin-980px" style="position: relative; z-index: 300;" data-animationappear="off"
           data-record-type="396"
           data-screen-min="980px"><!-- T396 -->

        <div class='t396'>
          <div class="t396__artboard" data-artboard-recid="472955815" data-artboard-screens="320,480,640,960,1200"
               data-artboard-height="0" data-artboard-valign="center" data-artboard-upscale="grid"
               data-artboard-ovrflw="visible"
          >
            <div class="t396__carrier" data-artboard-recid="472955815"></div>
            <div class="t396__filter" data-artboard-recid="472955815"></div>
            <div class='t396__elem tn-elem tn-elem__4729558151658831873641' data-elem-id='1658831873641'
                 data-elem-type='shape' data-field-top-value="0" data-field-left-value="-400"
                 data-field-height-value="100"
                 data-field-width-value="2000" data-field-axisy-value="top" data-field-axisx-value="left"
                 data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
                 data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-640-value="0"
                 data-field-left-res-640-value="-430" data-field-top-res-960-value="0"
                 data-field-left-res-960-value="-270"
                 data-field-height-res-960-value="100" data-field-width-res-960-value="1500"
            >
              <div class='tn-atom'></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849150' data-elem-id='1657718849150'
                 data-elem-type='button' data-field-top-value="30" data-field-left-value="1036"
                 data-field-height-value="40"
                 data-field-width-value="143" data-field-axisy-value="top" data-field-axisx-value="left"
                 data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
                 data-field-heightunits-value="" data-field-widthunits-value="" data-field-top-res-960-value="35"
                 data-field-left-res-960-value="830" data-field-height-res-960-value="30"
                 data-field-width-res-960-value="120"
            ><a class='tn-atom' href="#popup:myform">Оставить заявку</a></div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849145' data-elem-id='1657718849145'
                 data-elem-type='button' data-field-top-value="30" data-field-left-value="858"
                 data-field-height-value="40"
                 data-field-width-value="161" data-field-axisy-value="top" data-field-axisx-value="left"
                 data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
                 data-field-heightunits-value="" data-field-widthunits-value="" data-field-top-res-960-value="35"
                 data-field-left-res-960-value="680" data-field-height-res-960-value="30"
                 data-field-width-res-960-value="136"
            ><a class='tn-atom' href="tel:+78002500630">8 800 250-06-30</a></div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849142' data-elem-id='1657718849142'
                 data-elem-type='text' data-field-top-value="34" data-field-left-value="756" data-field-width-value="68"
                 data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
                 data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
                 data-field-widthunits-value="px" data-field-top-res-960-value="34" data-field-left-res-960-value="596"
                 data-field-width-res-960-value="69"
            >
              <div class='tn-atom'><a href="#partners" style="color: inherit">Партнеры</a></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849138' data-elem-id='1657718849138'
                 data-elem-type='text' data-field-top-value="34" data-field-left-value="640" data-field-width-value="84"
                 data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
                 data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
                 data-field-widthunits-value="px" data-field-top-res-960-value="34" data-field-left-res-960-value="499"
                 data-field-width-res-960-value="83"
            >
              <div class='tn-atom'><a href="#operator" style="color: inherit">Операторам</a></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849134' data-elem-id='1657718849134'
                 data-elem-type='text' data-field-top-value="34" data-field-left-value="529" data-field-width-value="79"
                 data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
                 data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
                 data-field-widthunits-value="px" data-field-top-res-960-value="34" data-field-left-res-960-value="409"
                 data-field-width-res-960-value="80"
            >
              <div class='tn-atom'><a href="#agency" style="color: inherit">Агентствам</a></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849128' data-elem-id='1657718849128'
                 data-elem-type='text' data-field-top-value="34" data-field-left-value="385"
                 data-field-width-value="112"
                 data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
                 data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
                 data-field-widthunits-value="px" data-field-top-res-960-value="34" data-field-left-res-960-value="287"
                 data-field-width-res-960-value="114"
            >
              <div class='tn-atom'><a href="#work" style="color: inherit">Как это работает</a></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849124' data-elem-id='1657718849124'
                 data-elem-type='text' data-field-top-value="34" data-field-left-value="250"
                 data-field-width-value="103"
                 data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
                 data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
                 data-field-widthunits-value="px" data-field-top-res-960-value="34" data-field-left-res-960-value="172"
                 data-field-width-res-960-value="108"
            >
              <div class='tn-atom'><a href="#preim" style="color: inherit">Преимущества</a></div>
            </div>
            <div class='t396__elem tn-elem tn-elem__4729558151657718849120' data-elem-id='1657718849120'
                 data-elem-type='shape' data-field-top-value="31" data-field-left-value="-1"
                 data-field-height-value="39"
                 data-field-width-value="210" data-field-axisy-value="top" data-field-axisx-value="left"
                 data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
                 data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-960-value="34"
                 data-field-left-res-960-value="-5" data-field-height-res-960-value="33"
                 data-field-width-res-960-value="178"
            ><a class='tn-atom t-bgimg' href="/"
                data-original="images/logo_blue.png"
                style="background-image:url('images/logo_blue.png');"
            ></a></div>
          </div>
        </div>
      </div>
    </a-affix>
    <div id="rec503435315" class="r t-rec t-screenmax-980px" style=" " data-animationappear="off" data-record-type="396"
         data-screen-max="980px"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="503435315" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="2" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-ovrflw="visible"
        >
          <div class="t396__carrier" data-artboard-recid="503435315"></div>
          <div class="t396__filter" data-artboard-recid="503435315"></div>
          <div class='t396__elem tn-elem tn-elem__5034353151666188122650' data-elem-id='1666188122650'
               data-elem-type='shape' data-field-top-value="51" data-field-left-value="33" data-field-height-value="2"
               data-field-width-value="22" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="51"
               data-field-left-res-320-value="28" data-field-top-res-480-value="51" data-field-left-res-480-value="28"
               data-field-top-res-640-value="51" data-field-left-res-640-value="28"
          ><a class='tn-atom' href="#menuopen"></a></div>
          <div class='t396__elem tn-elem tn-elem__5034353151666188120568' data-elem-id='1666188120568'
               data-elem-type='shape' data-field-top-value="43" data-field-left-value="33" data-field-height-value="2"
               data-field-width-value="22" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="43"
               data-field-left-res-320-value="28" data-field-top-res-480-value="43" data-field-left-res-480-value="28"
               data-field-top-res-640-value="43" data-field-left-res-640-value="28"
          ><a class='tn-atom' href="#menuopen"></a></div>
          <div class='t396__elem tn-elem tn-elem__5034353151666188085695' data-elem-id='1666188085695'
               data-elem-type='shape' data-field-top-value="35" data-field-left-value="33" data-field-height-value="2"
               data-field-width-value="22" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="35"
               data-field-left-res-320-value="28" data-field-top-res-480-value="35" data-field-left-res-480-value="28"
               data-field-top-res-640-value="35" data-field-left-res-640-value="28"
          ><a class='tn-atom' href="#menuopen"></a></div>
          <div class='t396__elem tn-elem tn-elem__5034353151666188024088' data-elem-id='1666188024088'
               data-elem-type='shape' data-field-top-value="20" data-field-left-value="20" data-field-height-value="48"
               data-field-width-value="48" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="20"
               data-field-left-res-320-value="15" data-field-top-res-480-value="20" data-field-left-res-480-value="15"
               data-field-top-res-640-value="20" data-field-left-res-640-value="15"
          ><a class='tn-atom' href="#menuopen"></a></div>
        </div>
      </div>
    </div>
    <div id="rec493765066" class="r t-rec t-rec_pt_0 t-rec_pb_0 t-screenmax-980px"
         style="padding-top:0px;padding-bottom:0px; " data-animationappear="off" data-record-type="450"
         data-screen-max="980px"><!-- t450 -->
      <div id="nav493765066marker"></div>
      <div class="t450__overlay">
        <div class="t450__overlay_bg"
             style="background-color:#003f52; "></div>
      </div>
      <div id="nav493765066" class="t450 " data-tooltip-hook="#menuopen"
           style="max-width: 300px;background-color: #0092be;">
        <button type="button"
                class="t450__close-button t450__close t450_opened "
                style="background-color: #0092be;"
                aria-label="Закрыть меню">
          <div class="t450__close_icon"><span style="background-color:#ffffff;"></span><span
              style="background-color:#ffffff;"></span><span style="background-color:#ffffff;"></span><span
              style="background-color:#ffffff;"></span></div>
        </button>
        <div class="t450__container t-align_left">
          <div class="t450__top">
            <nav class="t450__menu"
                 aria-label="Основные разделы на странице">
              <ul class="t450__list"
                  role="menubar" aria-label="Основные разделы на странице">
                <li class="t450__list_item"
                    role="none"
                ><a class="t-menu__link-item "
                    href="#preim"
                    role="menuitem" tabindex="0" data-menu-submenu-hook="" data-menu-item-number="1"
                    style="color:#ffffff;font-size:20px;font-family:'TTHoves';">Преимущества</a></li>
                <li class="t450__list_item"
                    role="none"
                ><a class="t-menu__link-item "
                    href="#work"
                    role="menuitem" tabindex="0" data-menu-submenu-hook="" data-menu-item-number="2"
                    style="color:#ffffff;font-size:20px;font-family:'TTHoves';">Как это работает</a></li>
                <li class="t450__list_item"
                    role="none"
                ><a class="t-menu__link-item "
                    href="#agency"
                    role="menuitem" tabindex="0" data-menu-submenu-hook="" data-menu-item-number="3"
                    style="color:#ffffff;font-size:20px;font-family:'TTHoves';">Агентствам</a></li>
                <li class="t450__list_item"
                    role="none"
                ><a class="t-menu__link-item "
                    href="#operator"
                    role="menuitem" tabindex="0" data-menu-submenu-hook="" data-menu-item-number="4"
                    style="color:#ffffff;font-size:20px;font-family:'TTHoves';">Операторам</a></li>
                <li class="t450__list_item"
                    role="none"
                ><a class="t-menu__link-item "
                    href="#partners"
                    role="menuitem" tabindex="0" data-menu-submenu-hook="" data-menu-item-number="5"
                    style="color:#ffffff;font-size:20px;font-family:'TTHoves';">Партнеры</a></li>
              </ul>
            </nav>
          </div>
          <div class="t450__rightside">
            <div class="t450__rightcontainer">
              <div class="t450__right_buttons">
                <div class="t450__right_buttons_wrap">
                  <div class="t450__right_buttons_but t450__right_buttons_but_two_left"><a href="tel:+78002500630"
                                                                                           target=""
                                                                                           class="t-btn t-btn_md "
                                                                                           style="color:#ffffff;border:1px solid #ffffff;border-radius:30px; -moz-border-radius:30px; -webkit-border-radius:30px;font-family:TTHoves;font-weight:400;">
                    <table role="presentation" style="width:100%; height:100%;">
                      <tr>
                        <td>8 800 250-06-30</td>
                      </tr>
                    </table>
                  </a></div>
                  <div class="t450__right_buttons_but t450__right_buttons_but_two_right"><a href="#popup:myform"
                                                                                            target=""
                                                                                            class="t-btn t-btn_md "
                                                                                            style="color:#ffffff;border:1px solid #ffffff;border-radius:30px; -moz-border-radius:30px; -webkit-border-radius:30px;font-family:TTHoves;font-weight:400;">
                    <table role="presentation" style="width:100%; height:100%;">
                      <tr>
                        <td>Оставить заявку</td>
                      </tr>
                    </table>
                  </a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div id="rec497164723" class="r t-rec t-screenmin-980px" style=" " data-animationappear="off" data-record-type="890"
         data-screen-min="980px"><!-- t890 -->
      <div class="t890" style="display: none; opacity:1; position:fixed; z-index:99990; bottom:130px;right:30px;">
        <button type="button"
                class="t890__arrow"
                aria-label="Вернуться к началу страницы"
                style="">
          <svg role="presentation" width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="50" fill="#178cbd" fill-opacity="1.0" stroke="none"/>
            <path d="M16 22l9-9 9 9" stroke="#ffffff" stroke-width="3" fill="none" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M25 14.2V35.9" stroke="#ffffff" stroke-width="3" fill="none" stroke-linecap="round"/>
          </svg>
        </button>
      </div>


    </div>
    <div id="rec498154312" class="r t-rec t-screenmax-980px" style=" " data-animationappear="off" data-record-type="890"
         data-screen-max="980px"><!-- t890 -->
      <div class="t890" style="display: none; opacity:1; position:fixed; z-index:99990; bottom:210px;right:12px;">
        <button type="button"
                class="t890__arrow"
                aria-label="Вернуться к началу страницы"
                style="">
          <svg role="presentation" width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="50" fill="#178cbd" fill-opacity="1.0" stroke="none"/>
            <path d="M16 22l9-9 9 9" stroke="#ffffff" stroke-width="3" fill="none" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M25 14.2V35.9" stroke="#ffffff" stroke-width="3" fill="none" stroke-linecap="round"/>
          </svg>
        </button>
      </div>


    </div>
    <div id="rec469194423" class="r t-rec" style=" " data-animationappear="off" data-record-type="131"><!-- T123 -->
      <div class="t123">
        <div class="t-container_100 ">
          <div class="t-width t-width_100 ">

            <!-- Слайдер из обложки CR30N и Zero block в Tilda | https://anku.im/blog/slider-with-cr30n-and-zero-block -->

          </div>
        </div>
      </div>
    </div>
    <div id="rec529522915" class="r t-rec" style=" " data-animationappear="off" data-record-type="702"><!-- T702 -->
      <div class="t702">
        <div class="t-popup" data-tooltip-hook="#popup2:presentForm"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
             aria-label="Хочу получить 15 000 ₽*">
          <div class="t-popup__container t-width t-width_6">
            <div class="t702__wrapper">
              <div class="t702__text-wrapper t-align_center">
                <div class="t702__title t-title t-title_xxs" style="">Хочу получить 15 000 ₽*</div>
              </div>
              <form id="form529522915" name='form529522915' role="form" action='' method='POST' data-formactiontype="2"
                    data-inputbox=".t-input-group" class="t-form js-form-proccess t-form_inputs-total_3 "
                    data-success-callback="t702_onSuccess">
                <form-component name="хочу 15000" :hideTextarea="true"/>
              </form>
            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>

    </div>
    <div id="rec529529370" class="r t-rec t-rec_pt_0 t-rec_pb_0" style="padding-top:0px;padding-bottom:0px; "
         data-record-type="390">
      <div class="t390">
        <div class="t-popup" data-tooltip-hook="#popup2:present"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
        >
          <div class="t-popup__container t-width t-width_10" style="background-color:#fbfbf9;"><img
              class="t390__img t-img"
              src="images/tild3365-3036-4665-a135-323933346238/-/empty/_15000-20.jpg?v=1"
              data-original="images/tild3365-3036-4665-a135-323933346238/_15000-20.jpg?v=1"
              imgfield="img"
              alt="">
            <div class="t390__wrapper t-align_center">
              <div class="t390__btn-wrapper"><a href="#popup2:presentForm" target=""
                                                class="t390__btn t390__btn_first t-btn t-btn_md "
                                                style=" color:#222222;border:2px solid #222222;border-radius:100px; -moz-border-radius:100px; -webkit-border-radius:100px;">
                <table role="presentation" style="width:100%; height:100%;">
                  <tr>
                    <td>button</td>
                  </tr>
                </table>
              </a></div>
            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>

    </div>
    <div id="rec529531414" class="r t-rec" style=" " data-animationappear="off" data-record-type="367"><!-- T367 -->
      <div class="t367"><a href="#popup2:present" class="t367__opener" data-trigger-time="3"
                           data-cookie-name="mytimercookie" data-cookie-time="1"></a></div>

    </div>
    <div id="rec468817268" class="r t-rec" style="background-color:#ffffff; " data-animationappear="off"
         data-record-type="734" data-bg-color="#ffffff"><!-- t734 -->
      <div class="t734">
        <div class="t-slds t734__witharrows" style="visibility: hidden;">
          <div class="t-container_100 t-slds__main">
            <ul class="t-slds__arrow_container ">
              <li class="t-slds__arrow_wrapper t-slds__arrow_wrapper-left" data-slide-direction="left">
                <button
                    type="button"
                    class="t-slds__arrow t-slds__arrow-left "
                    aria-controls="carousel_468817268"
                    aria-disabled="false"
                    aria-label="Предыдущий слайд"
                >
                  <div class="t-slds__arrow_body t-slds__arrow_body-left" style="width: 13px;">
                    <svg role="presentation" focusable="false" style="display: block" viewBox="0 0 13.3 25"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <polyline
                          fill="none"
                          stroke="#ffffff"
                          stroke-linejoin="butt"
                          stroke-linecap="butt"
                          stroke-width="1"
                          points="0.5,0.5 12.5,12.5 0.5,24.5"
                      />
                    </svg>
                  </div>
                </button>
              </li>
              <li class="t-slds__arrow_wrapper t-slds__arrow_wrapper-right" data-slide-direction="right">
                <button
                    type="button"
                    class="t-slds__arrow t-slds__arrow-right "
                    aria-controls="carousel_468817268"
                    aria-disabled="false"
                    aria-label="Следующий слайд"
                >
                  <div class="t-slds__arrow_body t-slds__arrow_body-right" style="width: 13px;">
                    <svg role="presentation" focusable="false" style="display: block" viewBox="0 0 13.3 25"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <polyline
                          fill="none"
                          stroke="#ffffff"
                          stroke-linejoin="butt"
                          stroke-linecap="butt"
                          stroke-width="1"
                          points="0.5,0.5 12.5,12.5 0.5,24.5"
                      />
                    </svg>
                  </div>
                </button>
              </li>
            </ul>
            <div class="t-slds__container">
              <ul class="t-slds__items-wrapper t-slds_animated-none"
                  id="carousel_468817268" data-slider-transition="300" data-slider-with-cycle="true"
                  data-slider-correct-height="false" data-auto-correct-mobile-width="false" data-slider-timeout="5000"
                  aria-live="off">
                <li class="t-slds__item t-slds__item_active" data-slide-index="1"
                    role="group"
                    aria-roledescription="slide"
                    aria-label="1 из 5">
                  <div class="t734__slds-wrapper t-slds__wrapper">
                    <div class="t-cover" id="recorddiv468817268" style="height:600px;">
                      <div class="t-cover__carrier" data-content-cover-id="468817268" id="coverCarry468817268"
                           data-content-cover-height="600px" data-content-cover-bg=""
                           style="background-image: url(''); height:600px;"
                           itemscope itemtype="http://schema.org/ImageObject">
                        <meta itemprop="image" content="">
                      </div>
                      <div class="t-cover__filter" style="height:600px; background-color:#000;opacity: 0.50; "></div>
                    </div>
                    <div class="t734__table">
                      <div class="t-cell t-valign_middle">
                        <div class="t-container">
                          <div class="t-cover__wrapper t-valign_middle">
                            <div class="t734__wrapper t-width t-width_8 t-margin_auto" data-hook-content="covercontent">
                              <div class="t734__textwrapper t-align_center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="t-slds__item" data-slide-index="2"
                    role="group"
                    aria-roledescription="slide"
                    aria-label="2 из 5">
                  <div class="t734__slds-wrapper t-slds__wrapper">
                    <div class="t-cover" style="height:600px;">
                      <div class="t-cover__carrier" data-content-cover-bg=""
                           style="background-image: url(''); height:600px;"
                           itemscope itemtype="http://schema.org/ImageObject">
                        <meta itemprop="image" content="">
                      </div>
                      <div class="t-cover__filter" style="height:600px; background-color:#000;opacity: 0.50; "></div>
                    </div>
                    <div class="t734__table">
                      <div class="t-cell t-valign_middle">
                        <div class="t-container">
                          <div class="t-cover__wrapper t-valign_middle">
                            <div class="t734__wrapper t-width t-width_8 t-margin_auto" data-hook-content="covercontent">
                              <div class="t734__textwrapper t-align_center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="t-slds__item" data-slide-index="3"
                    role="group"
                    aria-roledescription="slide"
                    aria-label="3 из 5">
                  <div class="t734__slds-wrapper t-slds__wrapper">
                    <div class="t-cover" style="height:600px;">
                      <div class="t-cover__carrier" data-content-cover-bg=""
                           style="background-image: url(''); height:600px;"
                           itemscope itemtype="http://schema.org/ImageObject">
                        <meta itemprop="image" content="">
                      </div>
                      <div class="t-cover__filter" style="height:600px; background-color:#000;opacity: 0.50; "></div>
                    </div>
                    <div class="t734__table">
                      <div class="t-cell t-valign_middle">
                        <div class="t-container">
                          <div class="t-cover__wrapper t-valign_middle">
                            <div class="t734__wrapper t-width t-width_8 t-margin_auto" data-hook-content="covercontent">
                              <div class="t734__textwrapper t-align_center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="t-slds__item" data-slide-index="4"
                    role="group"
                    aria-roledescription="slide"
                    aria-label="4 из 5">
                  <div class="t734__slds-wrapper t-slds__wrapper">
                    <div class="t-cover" style="height:600px;">
                      <div class="t-cover__carrier" data-content-cover-bg=""
                           style="background-image: url(''); height:600px;"
                           itemscope itemtype="http://schema.org/ImageObject">
                        <meta itemprop="image" content="">
                      </div>
                      <div class="t-cover__filter" style="height:600px; background-color:#000;opacity: 0.50; "></div>
                    </div>
                    <div class="t734__table">
                      <div class="t-cell t-valign_middle">
                        <div class="t-container">
                          <div class="t-cover__wrapper t-valign_middle">
                            <div class="t734__wrapper t-width t-width_8 t-margin_auto" data-hook-content="covercontent">
                              <div class="t734__textwrapper t-align_center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="t-slds__item" data-slide-index="5"
                    role="group"
                    aria-roledescription="slide"
                    aria-label="5 из 5">
                  <div class="t734__slds-wrapper t-slds__wrapper">
                    <div class="t-cover" style="height:600px;">
                      <div class="t-cover__carrier" data-content-cover-bg=""
                           style="background-image: url(''); height:600px;"
                           itemscope itemtype="http://schema.org/ImageObject">
                        <meta itemprop="image" content="">
                      </div>
                      <div class="t-cover__filter" style="height:600px; background-color:#000;opacity: 0.50; "></div>
                    </div>
                    <div class="t734__table">
                      <div class="t-cell t-valign_middle">
                        <div class="t-container">
                          <div class="t-cover__wrapper t-valign_middle">
                            <div class="t734__wrapper t-width t-width_8 t-margin_auto" data-hook-content="covercontent">
                              <div class="t734__textwrapper t-align_center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="t-slds__bullet_wrapper">
              <li class="t-slds__bullet t-slds__bullet_active" data-slide-bullet-for="1">
                <button
                    type="button"
                    class="t-slds__bullet_body"
                    aria-label="Перейти к слайду 1"
                    style="width: 10px; height: 10px;background-color: transparent;border: 2px solid #ffffff;"></button>
              </li>
              <li class="t-slds__bullet " data-slide-bullet-for="2">
                <button
                    type="button"
                    class="t-slds__bullet_body"
                    aria-label="Перейти к слайду 2"
                    style="width: 10px; height: 10px;background-color: transparent;border: 2px solid #ffffff;"></button>
              </li>
              <li class="t-slds__bullet " data-slide-bullet-for="3">
                <button
                    type="button"
                    class="t-slds__bullet_body"
                    aria-label="Перейти к слайду 3"
                    style="width: 10px; height: 10px;background-color: transparent;border: 2px solid #ffffff;"></button>
              </li>
              <li class="t-slds__bullet " data-slide-bullet-for="4">
                <button
                    type="button"
                    class="t-slds__bullet_body"
                    aria-label="Перейти к слайду 4"
                    style="width: 10px; height: 10px;background-color: transparent;border: 2px solid #ffffff;"></button>
              </li>
              <li class="t-slds__bullet " data-slide-bullet-for="5">
                <button
                    type="button"
                    class="t-slds__bullet_body"
                    aria-label="Перейти к слайду 5"
                    style="width: 10px; height: 10px;background-color: transparent;border: 2px solid #ffffff;"></button>
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
    <div id="rec508175773" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->
      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="508175773" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500"
        >
          <div class="t396__carrier" data-artboard-recid="508175773"></div>
          <div class="t396__filter" data-artboard-recid="508175773"></div>
          <div class='t396__elem tn-elem tn-elem__5081757731660668460707' data-elem-id='1660668460707'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="5760" data-field-fileheight-value="3200"
               data-field-top-res-960-value="1510" data-field-left-res-960-value="-220"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3131-3461-4139-b964-376430316534/a2e8324b-f752-4c8a-b.svg'
                                      imgfield='tn_img_1660668460707'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731660668515025' data-elem-id='1660668515025'
               data-elem-type='shape' data-field-top-value="504" data-field-left-value="37" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="350"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="389"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="483" data-field-left-res-640-value="27" data-field-top-res-960-value="504"
               data-field-left-res-960-value="38"
          ><a class='tn-atom t-bgimg'
              href="https://www.all-billboards.ru/dsp?location=1,1,58,95,97,114,118,122,124,128,135,136,140,505,986,1119,1231,1606,1712,1855,2571,141,147,148,150,153,154,155,156,158,161,162,165,166,167,169,170,175,176,209,223,225,232,576,1669,1674,2127&amp;side=A,B&amp;ttype=5&amp;dformat=0,4,3,2,1&amp;dspType=1&amp;owners=ADV%20Media,DF%20Media,Gallery,Maer,MosOblReklama,North%20Star%20Media,Russ%20Outdoor,VinEx,АРК,Игроник,Инсайт%20Медиа,Реклайн,Рекламное%20агентство%20«Неон»,Эфир&amp;avai=0&amp;flag_group_prism=0&amp;dsp=1&amp;searchDsp=1"
              target="_blank" data-original="images/tild6232-3239-4566-a666-313137393837/Button.png"
              style="background-image:url('images/tild6232-3239-4566-a666-313137393837/Button.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__5081757731660668460803' data-elem-id='1660668460803'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="867"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="106" data-field-left-res-320-value="19"
               data-field-width-res-320-value="287" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="487" data-field-top-res-640-value="194"
               data-field-left-res-640-value="20"
               data-field-width-res-640-value="609" data-field-top-res-960-value="246"
               data-field-left-res-960-value="30"
          ><h1 class='tn-atom' field='tn_text_1660668460803'>DSP ALL-BILLBOARDS </h1></div>
          <div class='t396__elem tn-elem tn-elem__5081757731663874802280' data-elem-id='1663874802280'
               data-elem-type='image' data-field-top-value="2850" data-field-left-value="-20"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="2140"
               data-field-left-res-640-value="-220"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3563-6539-4336-a232-393337383663/Rectangle_28.svg'
                                      imgfield='tn_img_1663874802280'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663872889843' data-elem-id='1663872889843'
               data-elem-type='image' data-field-top-value="183" data-field-left-value="0" data-field-width-value="14"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="14" data-field-fileheight-value="15"
               data-field-top-res-320-value="87" data-field-left-res-320-value="26" data-field-top-res-480-value="116"
               data-field-left-res-480-value="0" data-field-top-res-960-value="183" data-field-left-res-960-value="0"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6333-6161-4738-a162-626365353938/Group_17.svg'
                                      imgfield='tn_img_1663872889843'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663872824416' data-elem-id='1663872824416'
               data-elem-type='image' data-field-top-value="152" data-field-left-value="678" data-field-width-value="28"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="28" data-field-fileheight-value="28"
               data-field-top-res-320-value="21" data-field-left-res-320-value="617" data-field-top-res-480-value="85"
               data-field-left-res-480-value="678" data-field-top-res-960-value="152"
               data-field-left-res-960-value="678"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3765-6638-4532-b163-613764633635/Group_15.svg'
                                      imgfield='tn_img_1663872824416'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663872818979' data-elem-id='1663872818979'
               data-elem-type='image' data-field-top-value="698" data-field-left-value="144" data-field-width-value="23"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="23" data-field-fileheight-value="23"
               data-field-top-res-320-value="566" data-field-left-res-320-value="83" data-field-top-res-480-value="631"
               data-field-left-res-480-value="144" data-field-top-res-960-value="698"
               data-field-left-res-960-value="144"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3166-3465-4132-b834-663331363130/Group_16.svg'
                                      imgfield='tn_img_1663872818979'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663872777407' data-elem-id='1663872777407'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="717"
               data-field-top-res-320-value="-90" data-field-left-res-320-value="-180"
               data-field-top-res-480-value="-65"
               data-field-left-res-480-value="-120" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-120"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6464-3832-4265-a439-383765326433/Group_57.svg'
                                      imgfield='tn_img_1663872777407'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663872660171' data-elem-id='1663872660171'
               data-elem-type='image' data-field-top-value="211" data-field-left-value="478"
               data-field-width-value="843"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="843" data-field-fileheight-value="627"
               data-field-top-res-320-value="1035" data-field-left-res-320-value="-80"
               data-field-width-res-320-value="148" data-field-top-res-480-value="1265"
               data-field-left-res-480-value="160" data-field-width-res-480-value="443"
               data-field-top-res-640-value="392"
               data-field-left-res-640-value="281" data-field-width-res-640-value="544"
               data-field-top-res-960-value="283"
               data-field-left-res-960-value="428" data-field-width-res-960-value="720"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3931-3831-4139-b432-396136343065/Group_58.svg'
                                      imgfield='tn_img_1663872660171'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663875340796' data-elem-id='1663875340796'
               data-elem-type='image' data-field-top-value="2220" data-field-left-value="-170"
               data-field-width-value="960" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="960"
               data-field-fileheight-value="800" data-field-top-res-480-value="1360"
               data-field-left-res-480-value="-650"
               data-field-top-res-640-value="0" data-field-left-res-640-value="-160" data-field-top-res-960-value="1320"
               data-field-left-res-960-value="-174"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3430-3765-4833-b539-353831333166/Rectangle_28.svg'
                                      imgfield='tn_img_1663875340796'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663875852075' data-elem-id='1663875852075'
               data-elem-type='image' data-field-top-value="2470" data-field-left-value="440"
               data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="2695" data-field-left-res-320-value="-170" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3536-3436-4036-b163-663537373433/Rectangle_258.svg'
                                      imgfield='tn_img_1663875852075'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731663876841322' data-elem-id='1663876841322'
               data-elem-type='image' data-field-top-value="2285" data-field-left-value="810"
               data-field-width-value="480"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="480" data-field-fileheight-value="500"
               data-field-top-res-320-value="0" data-field-left-res-320-value="-80" data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3333-3339-4162-b035-353537333833/Rectangle_28.svg'
                                      imgfield='tn_img_1663876841322'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731664377816487' data-elem-id='1664377816487'
               data-elem-type='image' data-field-top-value="1555" data-field-left-value="-400"
               data-field-width-value="843" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1000"
               data-field-fileheight-value="627" data-field-top-res-320-value="25" data-field-left-res-320-value="160"
               data-field-width-res-320-value="166" data-field-top-res-480-value="445"
               data-field-left-res-480-value="100"
               data-field-width-res-480-value="512" data-field-top-res-640-value="1471"
               data-field-left-res-640-value="0"
               data-field-width-res-640-value="544" data-field-top-res-960-value="1213"
               data-field-left-res-960-value="-356" data-field-width-res-960-value="720"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3836-3530-4163-b734-303530656464/Group_58.svg'
                                      imgfield='tn_img_1664377816487'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5081757731660668460809' data-elem-id='1660668460809'
               data-elem-type='text' data-field-top-value="372" data-field-left-value="35" data-field-width-value="683"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="245" data-field-left-res-320-value="24"
               data-field-width-res-320-value="264" data-field-top-res-480-value="302"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="446" data-field-top-res-640-value="369"
               data-field-left-res-640-value="26"
               data-field-top-res-960-value="372" data-field-left-res-960-value="36"
          ><h2 class='tn-atom' field='tn_text_1660668460809'><span
              style="font-weight: 400;">Ведущая платформа для запуска </span><br><span style="font-weight: 400;">и управления наружной рекламой онлайн!</span>
          </h2></div>
        </div>
      </div>
    </div>
    <div id="rec514953014" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="514953014" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500"
        >
          <div class="t396__carrier" data-artboard-recid="514953014"></div>
          <div class="t396__filter" data-artboard-recid="514953014"></div>
          <div class='t396__elem tn-elem tn-elem__5149530141660668460707' data-elem-id='1660668460707'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="5760" data-field-fileheight-value="3200"
               data-field-top-res-960-value="1510" data-field-left-res-960-value="-220"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3131-3461-4139-b964-376430316534/a2e8324b-f752-4c8a-b.svg'
                                      imgfield='tn_img_1660668460707'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141660668515025' data-elem-id='1660668515025'
               data-elem-type='shape' data-field-top-value="504" data-field-left-value="37" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="350"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="389"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="483" data-field-left-res-640-value="27" data-field-top-res-960-value="504"
               data-field-left-res-960-value="38"
          ><a class='tn-atom t-bgimg'
              href="https://www.all-billboards.ru/dsp?location=1,1,58,95,97,114,118,122,124,128,135,136,140,505,986,1119,1231,1606,1712,1855,2571,141,147,148,150,153,154,155,156,158,161,162,165,166,167,169,170,175,176,209,223,225,232,576,1669,1674,2127&amp;side=A,B&amp;ttype=5&amp;dformat=0,4,3,2,1&amp;dspType=1&amp;owners=ADV%20Media,DF%20Media,Gallery,Maer,MosOblReklama,North%20Star%20Media,Russ%20Outdoor,VinEx,АРК,Игроник,Инсайт%20Медиа,Реклайн,Рекламное%20агентство%20«Неон»,Эфир&amp;avai=0&amp;flag_group_prism=0&amp;dsp=1&amp;searchDsp=1"
              target="_blank" data-original="images/tild6232-3239-4566-a666-313137393837/Button.png"
              style="background-image:url('images/tild6232-3239-4566-a666-313137393837/Button.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__5149530141660668460803' data-elem-id='1660668460803'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="867"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="106" data-field-left-res-320-value="19"
               data-field-width-res-320-value="287" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="487" data-field-top-res-640-value="194"
               data-field-left-res-640-value="20"
               data-field-width-res-640-value="609" data-field-top-res-960-value="246"
               data-field-left-res-960-value="30"
          ><h1 class='tn-atom' field='tn_text_1660668460803'>DSP ALL-BILLBOARDS </h1></div>
          <div class='t396__elem tn-elem tn-elem__5149530141663874802280' data-elem-id='1663874802280'
               data-elem-type='image' data-field-top-value="2850" data-field-left-value="-20"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="2140"
               data-field-left-res-640-value="-220"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3563-6539-4336-a232-393337383663/Rectangle_28.svg'
                                      imgfield='tn_img_1663874802280'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663872889843' data-elem-id='1663872889843'
               data-elem-type='image' data-field-top-value="183" data-field-left-value="0" data-field-width-value="14"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="14" data-field-fileheight-value="15"
               data-field-top-res-320-value="87" data-field-left-res-320-value="26" data-field-top-res-480-value="116"
               data-field-left-res-480-value="0" data-field-top-res-960-value="183" data-field-left-res-960-value="0"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6333-6161-4738-a162-626365353938/Group_17.svg'
                                      imgfield='tn_img_1663872889843'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663872824416' data-elem-id='1663872824416'
               data-elem-type='image' data-field-top-value="152" data-field-left-value="678" data-field-width-value="28"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="28" data-field-fileheight-value="28"
               data-field-top-res-320-value="21" data-field-left-res-320-value="617" data-field-top-res-480-value="85"
               data-field-left-res-480-value="678" data-field-top-res-960-value="152"
               data-field-left-res-960-value="678"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3765-6638-4532-b163-613764633635/Group_15.svg'
                                      imgfield='tn_img_1663872824416'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663872818979' data-elem-id='1663872818979'
               data-elem-type='image' data-field-top-value="698" data-field-left-value="144" data-field-width-value="23"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="23" data-field-fileheight-value="23"
               data-field-top-res-320-value="566" data-field-left-res-320-value="83" data-field-top-res-480-value="631"
               data-field-left-res-480-value="144" data-field-top-res-960-value="698"
               data-field-left-res-960-value="144"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3166-3465-4132-b834-663331363130/Group_16.svg'
                                      imgfield='tn_img_1663872818979'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663872777407' data-elem-id='1663872777407'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="717"
               data-field-top-res-320-value="-90" data-field-left-res-320-value="-180"
               data-field-top-res-480-value="-65"
               data-field-left-res-480-value="-120" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-120"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6464-3832-4265-a439-383765326433/Group_57.svg'
                                      imgfield='tn_img_1663872777407'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663872660171' data-elem-id='1663872660171'
               data-elem-type='image' data-field-top-value="211" data-field-left-value="478"
               data-field-width-value="843"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="843" data-field-fileheight-value="627"
               data-field-top-res-320-value="1035" data-field-left-res-320-value="-80"
               data-field-width-res-320-value="148" data-field-top-res-480-value="1265"
               data-field-left-res-480-value="160" data-field-width-res-480-value="443"
               data-field-top-res-640-value="392"
               data-field-left-res-640-value="281" data-field-width-res-640-value="544"
               data-field-top-res-960-value="283"
               data-field-left-res-960-value="428" data-field-width-res-960-value="720"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3931-3831-4139-b432-396136343065/Group_58.svg'
                                      imgfield='tn_img_1663872660171'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663875340796' data-elem-id='1663875340796'
               data-elem-type='image' data-field-top-value="2220" data-field-left-value="-170"
               data-field-width-value="960" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="960"
               data-field-fileheight-value="800" data-field-top-res-480-value="1360"
               data-field-left-res-480-value="-650"
               data-field-top-res-640-value="0" data-field-left-res-640-value="-160" data-field-top-res-960-value="1320"
               data-field-left-res-960-value="-174"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3430-3765-4833-b539-353831333166/Rectangle_28.svg'
                                      imgfield='tn_img_1663875340796'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663875852075' data-elem-id='1663875852075'
               data-elem-type='image' data-field-top-value="2470" data-field-left-value="440"
               data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="2695" data-field-left-res-320-value="-170" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3536-3436-4036-b163-663537373433/Rectangle_258.svg'
                                      imgfield='tn_img_1663875852075'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141663876841322' data-elem-id='1663876841322'
               data-elem-type='image' data-field-top-value="2285" data-field-left-value="810"
               data-field-width-value="480"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="480" data-field-fileheight-value="500"
               data-field-top-res-320-value="0" data-field-left-res-320-value="-80" data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3333-3339-4162-b035-353537333833/Rectangle_28.svg'
                                      imgfield='tn_img_1663876841322'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141664377816487' data-elem-id='1664377816487'
               data-elem-type='image' data-field-top-value="1555" data-field-left-value="-400"
               data-field-width-value="843" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1000"
               data-field-fileheight-value="627" data-field-top-res-320-value="25" data-field-left-res-320-value="160"
               data-field-width-res-320-value="166" data-field-top-res-480-value="445"
               data-field-left-res-480-value="100"
               data-field-width-res-480-value="512" data-field-top-res-640-value="1471"
               data-field-left-res-640-value="0"
               data-field-width-res-640-value="544" data-field-top-res-960-value="1213"
               data-field-left-res-960-value="-356" data-field-width-res-960-value="720"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3836-3530-4163-b734-303530656464/Group_58.svg'
                                      imgfield='tn_img_1664377816487'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5149530141660668460809' data-elem-id='1660668460809'
               data-elem-type='text' data-field-top-value="372" data-field-left-value="35" data-field-width-value="683"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="245" data-field-left-res-320-value="24"
               data-field-width-res-320-value="264" data-field-top-res-480-value="302"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="446" data-field-top-res-640-value="369"
               data-field-left-res-640-value="26"
               data-field-top-res-960-value="372" data-field-left-res-960-value="36"
          ><h2 class='tn-atom' field='tn_text_1660668460809'><span
              style="font-weight: 400;">Ведущая платформа для запуска </span><br><span style="font-weight: 400;">и управления наружной рекламой онлайн!</span>
          </h2></div>
        </div>
      </div>
    </div>
    <div id="rec468797218" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468797218" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500"
        >
          <div class="t396__carrier" data-artboard-recid="468797218"></div>
          <div class="t396__filter" data-artboard-recid="468797218"></div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737754020' data-elem-id='1657737754020'
               data-elem-type='image' data-field-top-value="43" data-field-left-value="-81"
               data-field-width-value="1624"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1389" data-field-fileheight-value="596"
               data-field-top-res-320-value="9" data-field-left-res-320-value="-325" data-field-top-res-480-value="16"
               data-field-left-res-480-value="-287" data-field-top-res-640-value="18"
               data-field-left-res-640-value="-81"
               data-field-top-res-960-value="43" data-field-left-res-960-value="-81"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3834-3932-4134-b433-336332633465/Vector_7.svg'
                                      imgfield='tn_img_1657737754020'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737863133' data-elem-id='1657737863133'
               data-elem-type='shape' data-field-top-value="599" data-field-left-value="37" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="350"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="475"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="565" data-field-left-res-640-value="37" data-field-top-res-960-value="599"
               data-field-left-res-960-value="38"
          ><a class='tn-atom t-bgimg' href="https://www.all-billboards.ru/dsp" target="_blank"
              data-original="images/tild3565-3964-4538-b565-653063666166/Button5.png"
              style="background-image:url('images/tild3565-3964-4538-b565-653063666166/Button5.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4687972181660667896531' data-elem-id='1660667896531'
               data-elem-type='shape' data-field-top-value="125" data-field-left-value="173"
               data-field-height-value="31.49" data-field-width-value="31" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-320-value="48" data-field-left-res-320-value="0" data-field-top-res-480-value="100"
               data-field-left-res-480-value="-17" data-field-top-res-640-value="101"
               data-field-left-res-640-value="173"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3661-6438-4232-b736-356532393563/Group_19.svg"
                 style="background-image:url('images/tild3661-6438-4232-b736-356532393563/Group_19.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181660667968656' data-elem-id='1660667968656'
               data-elem-type='shape' data-field-top-value="597" data-field-left-value="-71"
               data-field-height-value="20"
               data-field-width-value="20" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="563"
               data-field-left-res-320-value="-315" data-field-top-res-480-value="572"
               data-field-left-res-480-value="-261" data-field-top-res-640-value="573"
               data-field-left-res-640-value="-71"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3661-6438-4232-b736-356532393563/Group_19.svg"
                 style="background-image:url('images/tild3661-6438-4232-b736-356532393563/Group_19.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181660667975001' data-elem-id='1660667975001'
               data-elem-type='shape' data-field-top-value="167" data-field-left-value="653"
               data-field-height-value="20"
               data-field-width-value="20" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="29"
               data-field-left-res-320-value="298" data-field-top-res-480-value="142"
               data-field-left-res-480-value="463"
               data-field-top-res-640-value="143" data-field-left-res-640-value="653"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3661-6438-4232-b736-356532393563/Group_19.svg"
                 style="background-image:url('images/tild3661-6438-4232-b736-356532393563/Group_19.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181660667993588' data-elem-id='1660667993588'
               data-elem-type='shape' data-field-top-value="402" data-field-left-value="1196"
               data-field-height-value="20"
               data-field-width-value="20" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="369"
               data-field-left-res-320-value="952" data-field-top-res-480-value="378"
               data-field-left-res-480-value="1007"
               data-field-top-res-640-value="378" data-field-left-res-640-value="1196"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3661-6438-4232-b736-356532393563/Group_19.svg"
                 style="background-image:url('images/tild3661-6438-4232-b736-356532393563/Group_19.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181660671631663' data-elem-id='1660671631663'
               data-elem-type='shape' data-field-top-value="449" data-field-left-value="650"
               data-field-height-value="309"
               data-field-width-value="475" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="38"
               data-field-left-res-320-value="186" data-field-height-res-320-value="87"
               data-field-width-res-320-value="134" data-field-top-res-480-value="550"
               data-field-left-res-480-value="192"
               data-field-top-res-640-value="529" data-field-left-res-640-value="364"
               data-field-height-res-640-value="242" data-field-width-res-640-value="372"
               data-field-top-res-960-value="448" data-field-left-res-960-value="528"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6437-6530-4665-b332-613836626465/381_Converted.svg"
                 style="background-image:url('images/tild6437-6530-4665-b332-613836626465/381_Converted.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737753990' data-elem-id='1657737753990'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="1271"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="106" data-field-left-res-320-value="22"
               data-field-width-res-320-value="389" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="459" data-field-top-res-640-value="194"
               data-field-left-res-640-value="30"
               data-field-width-res-640-value="713" data-field-top-res-960-value="246"
               data-field-left-res-960-value="30"
          ><h2 class='tn-atom' field='tn_text_1657737753990'>ВЫБИРАЙ <br>ПРОВЕРЕННЫЕ РЕШЕНИЯ</h2></div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737753996' data-elem-id='1657737753996'
               data-elem-type='text' data-field-top-value="478" data-field-left-value="37" data-field-width-value="528"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="284" data-field-left-res-320-value="24"
               data-field-width-res-320-value="268" data-field-top-res-480-value="386"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="401" data-field-top-res-640-value="452"
               data-field-left-res-640-value="36"
               data-field-top-res-960-value="473" data-field-left-res-960-value="36"
          >
            <div class='tn-atom' field='tn_text_1657737753996'>Тысячи поверхностей, сотни клиентов, десятки операторов!
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181658835038561' data-elem-id='1658835038561'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="800"
               data-field-top-res-960-value="1580" data-field-left-res-960-value="-170"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3737-6264-4039-a266-346636323639/Rectangle_23.png'
                                      imgfield='tn_img_1658835038561'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181663877737498' data-elem-id='1663877737498'
               data-elem-type='image' data-field-top-value="1528" data-field-left-value="0"
               data-field-width-value="1200"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1200" data-field-fileheight-value="800"
               data-field-top-res-640-value="1630" data-field-left-res-640-value="-220" data-field-top-res-960-value="0"
               data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6135-3565-4964-b336-663135613132/01.svg'
                                      imgfield='tn_img_1663877737498'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181663877876446' data-elem-id='1663877876446'
               data-elem-type='image' data-field-top-value="1630" data-field-left-value="0" data-field-width-value="960"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="960" data-field-fileheight-value="800"
               data-field-top-res-480-value="1905" data-field-left-res-480-value="-90" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-160"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3536-3661-4936-b465-353836326134/02.svg'
                                      imgfield='tn_img_1663877876446'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181663878752905' data-elem-id='1663878752905'
               data-elem-type='image' data-field-top-value="1510" data-field-left-value="-470"
               data-field-width-value="640" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="640"
               data-field-fileheight-value="800" data-field-top-res-320-value="1845"
               data-field-left-res-320-value="-470"
               data-field-top-res-480-value="-1" data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6337-3432-4935-b866-346634303431/03.svg'
                                      imgfield='tn_img_1663878752905'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181663879510977' data-elem-id='1663879510977'
               data-elem-type='image' data-field-top-value="1390" data-field-left-value="300"
               data-field-width-value="480"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="480" data-field-fileheight-value="500"
               data-field-top-res-320-value="0" data-field-left-res-320-value="-80" data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3439-6237-4266-b034-323661646166/Rectangle_28.svg'
                                      imgfield='tn_img_1663879510977'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737754035' data-elem-id='1657737754035'
               data-elem-type='shape' data-field-top-value="713" data-field-left-value="855"
               data-field-height-value="21"
               data-field-width-value="21" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687972181657737754039' data-elem-id='1657737754039'
               data-elem-type='shape' data-field-top-value="718" data-field-left-value="861"
               data-field-height-value="11"
               data-field-width-value="11" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px"
          >
            <div class='tn-atom'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec472968354" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="472968354" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500"
        >
          <div class="t396__carrier" data-artboard-recid="472968354"></div>
          <div class="t396__filter" data-artboard-recid="472968354"></div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740385222' data-elem-id='1657740385222'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="1271"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="106" data-field-left-res-320-value="21"
               data-field-top-res-480-value="145" data-field-left-res-480-value="30"
               data-field-width-res-480-value="526"
               data-field-top-res-640-value="194" data-field-left-res-640-value="30"
               data-field-width-res-640-value="687"
               data-field-top-res-960-value="246" data-field-left-res-960-value="30"
          ><h2 class='tn-atom' field='tn_text_1657740385222'>НАРУЖНАЯ РЕКЛАМА?</h2></div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740391540' data-elem-id='1657740391540'
               data-elem-type='image' data-field-top-value="-1" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="801"
               data-field-top-res-640-value="3580" data-field-left-res-640-value="-350"
               data-field-top-res-960-value="1440" data-field-left-res-960-value="-220"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6336-3534-4439-b537-663865343232/Rectangle_89.png'
                                      imgfield='tn_img_1657740391540'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740409556' data-elem-id='1657740409556'
               data-elem-type='shape' data-field-top-value="510" data-field-left-value="38" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="350"
               data-field-left-res-320-value="25" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="404"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="491" data-field-left-res-640-value="37" data-field-top-res-960-value="509"
               data-field-left-res-960-value="28"
          ><a class='tn-atom t-bgimg' href="https://www.all-billboards.ru/dsp" target="_blank"
              data-original="images/tild3565-3964-4538-b565-653063666166/Button5.png"
              style="background-image:url('images/tild3565-3964-4538-b565-653063666166/Button5.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740539923' data-elem-id='1657740539923'
               data-elem-type='shape' data-field-top-value="110" data-field-left-value="-121"
               data-field-height-value="677" data-field-width-value="1441" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-320-value="-34" data-field-left-res-320-value="-164" data-field-top-res-640-value="10"
               data-field-left-res-640-value="-162"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6561-6564-4338-b862-376237326337/Group_30.svg"
                 style="background-image:url('images/tild6561-6564-4338-b862-376237326337/Group_30.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740697513' data-elem-id='1657740697513'
               data-elem-type='shape' data-field-top-value="345" data-field-left-value="579"
               data-field-height-value="455"
               data-field-width-value="753" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="14"
               data-field-left-res-320-value="177" data-field-height-res-320-value="92"
               data-field-width-res-320-value="150" data-field-top-res-480-value="471"
               data-field-left-res-480-value="188"
               data-field-height-res-480-value="328" data-field-width-res-480-value="543"
               data-field-top-res-640-value="427" data-field-left-res-640-value="336" data-field-top-res-960-value="371"
               data-field-left-res-960-value="527"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3465-6362-4738-b535-383136393332/6410_Converted_1.svg"
                 style="background-image:url('images/tild3465-6362-4738-b535-383136393332/6410_Converted_1.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541663881234840' data-elem-id='1663881234840'
               data-elem-type='image' data-field-top-value="1240" data-field-left-value="-50"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="1464"
               data-field-left-res-640-value="-108"
               data-field-top-res-960-value="-1" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6363-3933-4861-a438-346239623433/01.svg'
                                      imgfield='tn_img_1663881234840'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541663881415910' data-elem-id='1663881415910'
               data-elem-type='image' data-field-top-value="1345" data-field-left-value="-300"
               data-field-width-value="960" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="960"
               data-field-fileheight-value="800" data-field-top-res-480-value="1364"
               data-field-left-res-480-value="-300"
               data-field-top-res-640-value="0" data-field-left-res-640-value="-160"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6633-3736-4131-a666-303462326435/02.svg'
                                      imgfield='tn_img_1663881415910'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541663881806104' data-elem-id='1663881806104'
               data-elem-type='image' data-field-top-value="1155" data-field-left-value="260"
               data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="1216" data-field-left-res-320-value="-300" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6435-3736-4339-a130-393061316166/03.svg'
                                      imgfield='tn_img_1663881806104'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541663882008236' data-elem-id='1663882008236'
               data-elem-type='image' data-field-top-value="1040" data-field-left-value="-140"
               data-field-width-value="480" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="480"
               data-field-fileheight-value="500" data-field-top-res-320-value="0" data-field-left-res-320-value="-80"
               data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6637-6230-4733-b338-326639653735/Rectangle_28.svg'
                                      imgfield='tn_img_1663882008236'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063917116' data-elem-id='1662063917116'
               data-elem-type='shape' data-field-top-value="461" data-field-left-value="517"
               data-field-height-value="10"
               data-field-width-value="10" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="318"
               data-field-left-res-320-value="475" data-field-top-res-640-value="360"
               data-field-left-res-640-value="476"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063917107' data-elem-id='1662063917107'
               data-elem-type='image' data-field-top-value="456" data-field-left-value="512" data-field-width-value="20"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="314" data-field-left-res-320-value="472" data-field-top-res-640-value="356"
               data-field-left-res-640-value="472"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3134-3265-4934-b432-643230313935/Ellipse_8.svg'
                                      imgfield='tn_img_1662063917107'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063950799' data-elem-id='1662063950799'
               data-elem-type='shape' data-field-top-value="181" data-field-left-value="232"
               data-field-height-value="10"
               data-field-width-value="10" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="81"
               data-field-left-res-320-value="41" data-field-top-res-640-value="81" data-field-left-res-640-value="191"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063950812' data-elem-id='1662063950812'
               data-elem-type='image' data-field-top-value="176" data-field-left-value="227" data-field-width-value="20"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="76" data-field-left-res-320-value="36" data-field-top-res-640-value="76"
               data-field-left-res-640-value="186"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6530-3833-4265-b334-373763323632/Ellipse_8.svg'
                                      imgfield='tn_img_1662063950812'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063959520' data-elem-id='1662063959520'
               data-elem-type='shape' data-field-top-value="215" data-field-left-value="-3" data-field-height-value="8"
               data-field-width-value="8" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="73"
               data-field-left-res-320-value="-44" data-field-top-res-640-value="115"
               data-field-left-res-640-value="-43"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662063959556' data-elem-id='1662063959556'
               data-elem-type='image' data-field-top-value="211" data-field-left-value="-7" data-field-width-value="15"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="69" data-field-left-res-320-value="-47" data-field-top-res-640-value="111"
               data-field-left-res-640-value="-47"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6138-3161-4962-b338-303435353865/Ellipse_8.svg'
                                      imgfield='tn_img_1662063959556'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541662114990099' data-elem-id='1662114990099'
               data-elem-type='text' data-field-top-value="414" data-field-left-value="184" data-field-width-value="542"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="270" data-field-left-res-320-value="137"
               data-field-width-res-320-value="282" data-field-top-res-480-value="340"
               data-field-left-res-480-value="136"
               data-field-width-res-480-value="375" data-field-top-res-640-value="412"
               data-field-left-res-640-value="178"
               data-field-top-res-960-value="414" data-field-left-res-960-value="184"
          >
            <div class='tn-atom' field='tn_text_1662114990099'>1 000 руб.</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4729683541657740404876' data-elem-id='1657740404876'
               data-elem-type='text' data-field-top-value="378" data-field-left-value="36" data-field-width-value="542"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="251" data-field-left-res-320-value="23"
               data-field-width-res-320-value="282" data-field-top-res-480-value="314"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="375" data-field-top-res-640-value="376"
               data-field-left-res-640-value="36"
               data-field-top-res-960-value="378" data-field-left-res-960-value="36"
          >
            <div class='tn-atom' field='tn_text_1657740404876'>Это недорого! Кампании от одного дня, бюджет от</div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec481508138" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="481508138" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500"
        >
          <div class="t396__carrier" data-artboard-recid="481508138"></div>
          <div class="t396__filter" data-artboard-recid="481508138"></div>
          <div class='t396__elem tn-elem tn-elem__4815081381657737863133' data-elem-id='1657737863133'
               data-elem-type='shape' data-field-top-value="576" data-field-left-value="38" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="350"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="420"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="464" data-field-left-res-640-value="27" data-field-top-res-960-value="557"
               data-field-left-res-960-value="38"
          ><a class='tn-atom t-bgimg' href="https://www.all-billboards.ru/dsp" target="_blank"
              data-original="images/tild3565-3964-4538-b565-653063666166/Button5.png"
              style="background-image:url('images/tild3565-3964-4538-b565-653063666166/Button5.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4815081381657737753990' data-elem-id='1657737753990'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="1271"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="106" data-field-left-res-320-value="22"
               data-field-width-res-320-value="295" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="427" data-field-top-res-640-value="194"
               data-field-left-res-640-value="20"
               data-field-width-res-640-value="683" data-field-top-res-960-value="246"
               data-field-left-res-960-value="30"
          ><h2 class='tn-atom' field='tn_text_1657737753990'>АКЦИЯ, РАСПРОДАЖА, СПЕЦПРЕДЛОЖЕНИЕ?</h2></div>
          <div class='t396__elem tn-elem tn-elem__4815081381657737753996' data-elem-id='1657737753996'
               data-elem-type='text' data-field-top-value="444" data-field-left-value="36" data-field-width-value="607"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="239" data-field-left-res-320-value="24"
               data-field-width-res-320-value="280" data-field-top-res-480-value="332"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="418" data-field-top-res-640-value="351"
               data-field-left-res-640-value="26"
               data-field-width-res-640-value="571" data-field-top-res-960-value="425"
               data-field-left-res-960-value="36"
          >
            <div class='tn-atom' field='tn_text_1657737753996'>Бронируйте цифровые щиты на любой срок! От 0,84 руб. за
              показ!
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381658835165460' data-elem-id='1658835165460'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="800"
               data-field-top-res-960-value="1615" data-field-left-res-960-value="-170"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3266-6630-4731-a539-386339383736/Rectangle_6.png'
                                      imgfield='tn_img_1658835165460'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064221512' data-elem-id='1662064221512'
               data-elem-type='image' data-field-top-value="154" data-field-left-value="-120"
               data-field-width-value="1440" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1440"
               data-field-fileheight-value="544" data-field-top-res-320-value="-205"
               data-field-left-res-320-value="-200"
               data-field-top-res-640-value="92" data-field-left-res-640-value="-160"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3163-3461-4231-b963-616461623263/Group_3.svg'
                                      imgfield='tn_img_1662064221512'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064325368' data-elem-id='1662064325368'
               data-elem-type='shape' data-field-top-value="589" data-field-left-value="-66" data-field-height-value="9"
               data-field-width-value="9" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="445"
               data-field-left-res-320-value="25" data-field-top-res-480-value="598" data-field-left-res-480-value="29"
               data-field-top-res-640-value="526" data-field-left-res-640-value="-106"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064325377' data-elem-id='1662064325377'
               data-elem-type='image' data-field-top-value="585" data-field-left-value="-71" data-field-width-value="16"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="440" data-field-left-res-320-value="20" data-field-top-res-480-value="593"
               data-field-left-res-480-value="24" data-field-top-res-640-value="522"
               data-field-left-res-640-value="-112"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3735-3165-4738-a362-633030613761/Ellipse_8.svg'
                                      imgfield='tn_img_1662064325377'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662110089783' data-elem-id='1662110089783'
               data-elem-type='image' data-field-top-value="280" data-field-left-value="732"
               data-field-width-value="487"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="2710" data-field-fileheight-value="3021"
               data-field-top-res-320-value="0" data-field-left-res-320-value="180" data-field-width-res-320-value="132"
               data-field-top-res-480-value="429" data-field-left-res-480-value="229"
               data-field-width-res-480-value="310"
               data-field-top-res-640-value="415" data-field-left-res-640-value="288"
               data-field-width-res-640-value="373"
               data-field-top-res-960-value="303" data-field-left-res-960-value="587"
               data-field-width-res-960-value="465"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3538-6439-4139-b564-313134356536/9.svg'
                                      imgfield='tn_img_1662110089783'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381663880130404' data-elem-id='1663880130404'
               data-elem-type='image' data-field-top-value="1970" data-field-left-value="-30"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="1520"
               data-field-left-res-640-value="-190"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3533-3037-4364-b530-373931643030/01.svg'
                                      imgfield='tn_img_1663880130404'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381663880566412' data-elem-id='1663880566412'
               data-elem-type='image' data-field-top-value="1580" data-field-left-value="80"
               data-field-width-value="960"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="960" data-field-fileheight-value="800"
               data-field-top-res-480-value="1390" data-field-left-res-480-value="-310" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-160" data-field-top-res-960-value="1360"
               data-field-left-res-960-value="-90"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3734-3932-4862-b034-656466623438/02.svg'
                                      imgfield='tn_img_1663880566412'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381663880745289' data-elem-id='1663880745289'
               data-elem-type='image' data-field-top-value="1155" data-field-left-value="500"
               data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="1194" data-field-left-res-320-value="0" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80" data-field-top-res-640-value="1295"
               data-field-left-res-640-value="-30"
               data-field-top-res-960-value="1615" data-field-left-res-960-value="310"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3434-6261-4163-b439-666362633965/03.svg'
                                      imgfield='tn_img_1663880745289'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381663880906959' data-elem-id='1663880906959'
               data-elem-type='image' data-field-top-value="1280" data-field-left-value="-220"
               data-field-width-value="480" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="480"
               data-field-fileheight-value="500" data-field-top-res-320-value="0" data-field-left-res-320-value="-80"
               data-field-width-res-320-value="480" data-field-top-res-480-value="980"
               data-field-left-res-480-value="520"
               data-field-top-res-640-value="1165" data-field-left-res-640-value="600"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3732-6566-4137-b961-306666373764/Rectangle_28.svg'
                                      imgfield='tn_img_1663880906959'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064289013' data-elem-id='1662064289013'
               data-elem-type='shape' data-field-top-value="219" data-field-left-value="984"
               data-field-height-value="14"
               data-field-width-value="14" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="5"
               data-field-left-res-320-value="942" data-field-top-res-640-value="156"
               data-field-left-res-640-value="943"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064288982' data-elem-id='1662064288982'
               data-elem-type='image' data-field-top-value="212" data-field-left-value="977" data-field-width-value="27"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="0" data-field-left-res-320-value="937" data-field-top-res-640-value="150"
               data-field-left-res-640-value="937"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3661-6461-4466-b630-636333393165/Ellipse_8.svg'
                                      imgfield='tn_img_1662064288982'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064304466' data-elem-id='1662064304466'
               data-elem-type='shape' data-field-top-value="241" data-field-left-value="1141"
               data-field-height-value="7"
               data-field-width-value="7" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064304473' data-elem-id='1662064304473'
               data-elem-type='image' data-field-top-value="238" data-field-left-value="1138"
               data-field-width-value="13"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3233-3262-4230-a265-316430376336/Ellipse_8.svg'
                                      imgfield='tn_img_1662064304473'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064314725' data-elem-id='1662064314725'
               data-elem-type='shape' data-field-top-value="667" data-field-left-value="364"
               data-field-height-value="14"
               data-field-width-value="14" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="309"
               data-field-left-res-320-value="282" data-field-top-res-640-value="605"
               data-field-left-res-640-value="323"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4815081381662064314732' data-elem-id='1662064314732'
               data-elem-type='image' data-field-top-value="660" data-field-left-value="357" data-field-width-value="27"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="302" data-field-left-res-320-value="276" data-field-top-res-640-value="598"
               data-field-left-res-640-value="316"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3266-6337-4933-b166-373031303165/Ellipse_8.svg'
                                      imgfield='tn_img_1662064314732'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468815779" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468815779" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500" data-artboard-upscale-res-320="grid"
        >
          <div class="t396__carrier" data-artboard-recid="468815779"></div>
          <div class="t396__filter" data-artboard-recid="468815779"></div>
          <div class='t396__elem tn-elem tn-elem__4688157791657737863133' data-elem-id='1657737863133'
               data-elem-type='shape' data-field-top-value="612" data-field-left-value="38" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="351"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="495"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="524" data-field-left-res-640-value="37" data-field-top-res-960-value="612"
               data-field-left-res-960-value="48"
          ><a class='tn-atom t-bgimg' href="https://www.all-billboards.ru/dsp" target="_blank"
              data-original="images/tild3565-3964-4538-b565-653063666166/Button5.png"
              style="background-image:url('images/tild3565-3964-4538-b565-653063666166/Button5.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4688157791657737753996' data-elem-id='1657737753996'
               data-elem-type='text' data-field-top-value="445" data-field-left-value="36" data-field-width-value="607"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="272" data-field-left-res-320-value="24"
               data-field-width-res-320-value="313" data-field-top-res-480-value="383"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="390" data-field-top-res-640-value="376"
               data-field-left-res-640-value="36"
               data-field-width-res-640-value="560" data-field-top-res-960-value="448"
               data-field-left-res-960-value="36"
          >
            <div class='tn-atom' field='tn_text_1657737753996'>Онлайн отчетность. Мощная аналитика. <br>Гибкие
              настройки.
              Умные алгоритмы. Выбор стратегий.
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791658835182286' data-elem-id='1658835182286'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="800"
               data-field-top-res-960-value="1240" data-field-left-res-960-value="-140"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3231-3836-4863-b035-623832303939/Rectangle_6.png'
                                      imgfield='tn_img_1658835182286'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791663882501376' data-elem-id='1663882501376'
               data-elem-type='image' data-field-top-value="1585" data-field-left-value="-80"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="1600"
               data-field-left-res-640-value="-238"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6365-3366-4634-a334-613165666537/01.svg'
                                      imgfield='tn_img_1663882501376'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791663882768779' data-elem-id='1663882768779'
               data-elem-type='image' data-field-top-value="1360" data-field-left-value="-50"
               data-field-width-value="960"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="960" data-field-fileheight-value="800"
               data-field-top-res-480-value="1365" data-field-left-res-480-value="-390" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-160" data-field-top-res-960-value="1132"
               data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6566-3863-4137-b639-613731613631/02.svg'
                                      imgfield='tn_img_1663882768779'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791657737753990' data-elem-id='1657737753990'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="611"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="100" data-field-left-res-320-value="22"
               data-field-width-res-320-value="274" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="461" data-field-top-res-640-value="194"
               data-field-left-res-640-value="30"
               data-field-top-res-960-value="246" data-field-left-res-960-value="30"
          ><h2 class='tn-atom' field='tn_text_1657737753990'>ВСЕГДА ВПЕРЕДИ КОНКУРЕНТОВ!</h2></div>
          <div class='t396__elem tn-elem tn-elem__4688157791663883180299' data-elem-id='1663883180299'
               data-elem-type='image' data-field-top-value="1196" data-field-left-value="0" data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="1115" data-field-left-res-320-value="-360" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6237-3131-4233-a636-613165376537/03.svg'
                                      imgfield='tn_img_1663883180299'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791663883263843' data-elem-id='1663883263843'
               data-elem-type='image' data-field-top-value="1108" data-field-left-value="540"
               data-field-width-value="480"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="480" data-field-fileheight-value="500"
               data-field-top-res-320-value="0" data-field-left-res-320-value="-80" data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3636-3561-4665-b332-386636343034/Rectangle_28.svg'
                                      imgfield='tn_img_1663883263843'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791661513472361' data-elem-id='1661513472361'
               data-elem-type='image' data-field-top-value="214" data-field-left-value="529"
               data-field-width-value="620"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="2700" data-field-fileheight-value="2700"
               data-field-top-res-320-value="10" data-field-left-res-320-value="170"
               data-field-width-res-320-value="140"
               data-field-top-res-480-value="475" data-field-left-res-480-value="160"
               data-field-width-res-480-value="358"
               data-field-top-res-640-value="432" data-field-left-res-640-value="260"
               data-field-width-res-640-value="425"
               data-field-top-res-960-value="345" data-field-left-res-960-value="420"
               data-field-width-res-960-value="516"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6537-6231-4735-b462-623638353333/Frame_7.svg'
                                      imgfield='tn_img_1661513472361'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064559269' data-elem-id='1662064559269'
               data-elem-type='shape' data-field-top-value="199" data-field-left-value="1179"
               data-field-height-value="6"
               data-field-width-value="6" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="137"
               data-field-left-res-320-value="795" data-field-top-res-480-value="132"
               data-field-left-res-480-value="1081"
               data-field-top-res-640-value="199" data-field-left-res-640-value="1179"
               data-field-top-res-960-value="199"
               data-field-left-res-960-value="1179"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064519689' data-elem-id='1662064519689'
               data-elem-type='shape' data-field-top-value="387" data-field-left-value="609" data-field-height-value="9"
               data-field-width-value="9" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="325"
               data-field-left-res-320-value="225" data-field-top-res-480-value="320"
               data-field-left-res-480-value="511"
               data-field-top-res-640-value="387" data-field-left-res-640-value="609" data-field-top-res-960-value="387"
               data-field-left-res-960-value="609"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064559276' data-elem-id='1662064559276'
               data-elem-type='image' data-field-top-value="196" data-field-left-value="1176"
               data-field-width-value="12"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="134" data-field-left-res-320-value="792" data-field-top-res-480-value="129"
               data-field-left-res-480-value="1078" data-field-top-res-640-value="196"
               data-field-left-res-640-value="1176" data-field-top-res-960-value="196"
               data-field-left-res-960-value="1176"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3330-3835-4231-b965-323730646461/Ellipse_8.svg'
                                      imgfield='tn_img_1662064559276'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064519679' data-elem-id='1662064519679'
               data-elem-type='image' data-field-top-value="382" data-field-left-value="604" data-field-width-value="18"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="320" data-field-left-res-320-value="220" data-field-top-res-480-value="315"
               data-field-left-res-480-value="506" data-field-top-res-640-value="382"
               data-field-left-res-640-value="604"
               data-field-top-res-960-value="382" data-field-left-res-960-value="604"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6464-3238-4638-b862-353231326633/Ellipse_8.svg'
                                      imgfield='tn_img_1662064519679'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064506218' data-elem-id='1662064506218'
               data-elem-type='shape' data-field-top-value="150" data-field-left-value="542" data-field-height-value="6"
               data-field-width-value="6" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="88"
               data-field-left-res-320-value="158" data-field-top-res-480-value="83" data-field-left-res-480-value="444"
               data-field-top-res-640-value="150" data-field-left-res-640-value="542" data-field-top-res-960-value="150"
               data-field-left-res-960-value="542"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064499625' data-elem-id='1662064499625'
               data-elem-type='shape' data-field-top-value="133" data-field-left-value="64" data-field-height-value="14"
               data-field-width-value="14" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="71"
               data-field-left-res-320-value="-320" data-field-top-res-480-value="66"
               data-field-left-res-480-value="-34"
               data-field-top-res-640-value="133" data-field-left-res-640-value="64" data-field-top-res-960-value="133"
               data-field-left-res-960-value="64"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064506212' data-elem-id='1662064506212'
               data-elem-type='image' data-field-top-value="146" data-field-left-value="538" data-field-width-value="12"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="84" data-field-left-res-320-value="154" data-field-top-res-480-value="79"
               data-field-left-res-480-value="440" data-field-top-res-640-value="146"
               data-field-left-res-640-value="538"
               data-field-top-res-960-value="146" data-field-left-res-960-value="538"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6434-3733-4362-b935-633637393565/Ellipse_8.svg'
                                      imgfield='tn_img_1662064506212'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064499613' data-elem-id='1662064499613'
               data-elem-type='image' data-field-top-value="127" data-field-left-value="58" data-field-width-value="27"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="65" data-field-left-res-320-value="-326" data-field-top-res-480-value="60"
               data-field-left-res-480-value="-40" data-field-top-res-640-value="127" data-field-left-res-640-value="58"
               data-field-top-res-960-value="127" data-field-left-res-960-value="58"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3331-3865-4633-a332-643835313266/Ellipse_8.svg'
                                      imgfield='tn_img_1662064499613'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4688157791662064474165' data-elem-id='1662064474165'
               data-elem-type='image' data-field-top-value="108" data-field-left-value="-120"
               data-field-width-value="1440" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1440"
               data-field-fileheight-value="500" data-field-top-res-320-value="46" data-field-left-res-320-value="-504"
               data-field-top-res-480-value="41" data-field-left-res-480-value="-218" data-field-top-res-640-value="108"
               data-field-left-res-640-value="-120" data-field-top-res-960-value="108"
               data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6430-3132-4632-a231-303936626166/Group_37.svg'
                                      imgfield='tn_img_1662064474165'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec513697683" class="r t-rec" style=" " data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="513697683" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="800" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="500" data-artboard-upscale-res-320="grid"
        >
          <div class="t396__carrier" data-artboard-recid="513697683"></div>
          <div class="t396__filter" data-artboard-recid="513697683"></div>
          <div class='t396__elem tn-elem tn-elem__5136976831657737863133' data-elem-id='1657737863133'
               data-elem-type='shape' data-field-top-value="612" data-field-left-value="38" data-field-height-value="76"
               data-field-width-value="275" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="351"
               data-field-left-res-320-value="26" data-field-height-res-320-value="56"
               data-field-width-res-320-value="202" data-field-top-res-480-value="495"
               data-field-left-res-480-value="37"
               data-field-height-res-480-value="69" data-field-width-res-480-value="250"
               data-field-top-res-640-value="524" data-field-left-res-640-value="37" data-field-top-res-960-value="612"
               data-field-left-res-960-value="48"
          ><a class='tn-atom t-bgimg' href="https://www.all-billboards.ru/dsp" target="_blank"
              data-original="images/tild3565-3964-4538-b565-653063666166/Button5.png"
              style="background-image:url('images/tild3565-3964-4538-b565-653063666166/Button5.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__5136976831657737753996' data-elem-id='1657737753996'
               data-elem-type='text' data-field-top-value="445" data-field-left-value="36" data-field-width-value="607"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="272" data-field-left-res-320-value="24"
               data-field-width-res-320-value="313" data-field-top-res-480-value="383"
               data-field-left-res-480-value="36"
               data-field-width-res-480-value="390" data-field-top-res-640-value="376"
               data-field-left-res-640-value="36"
               data-field-width-res-640-value="560" data-field-top-res-960-value="448"
               data-field-left-res-960-value="36"
          >
            <div class='tn-atom' field='tn_text_1657737753996'>Онлайн отчетность. Мощная аналитика. <br>Гибкие
              настройки.
              Умные алгоритмы. Выбор стратегий.
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831658835182286' data-elem-id='1658835182286'
               data-elem-type='image' data-field-top-value="0" data-field-left-value="-120"
               data-field-width-value="1440"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="1440" data-field-fileheight-value="800"
               data-field-top-res-960-value="1240" data-field-left-res-960-value="-140"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3231-3836-4863-b035-623832303939/Rectangle_6.png'
                                      imgfield='tn_img_1658835182286'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831663882501376' data-elem-id='1663882501376'
               data-elem-type='image' data-field-top-value="1585" data-field-left-value="-80"
               data-field-width-value="1200" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1200"
               data-field-fileheight-value="800" data-field-top-res-640-value="1600"
               data-field-left-res-640-value="-238"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6365-3366-4634-a334-613165666537/01.svg'
                                      imgfield='tn_img_1663882501376'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831663882768779' data-elem-id='1663882768779'
               data-elem-type='image' data-field-top-value="1360" data-field-left-value="-50"
               data-field-width-value="960"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="960" data-field-fileheight-value="800"
               data-field-top-res-480-value="1365" data-field-left-res-480-value="-390" data-field-top-res-640-value="0"
               data-field-left-res-640-value="-160" data-field-top-res-960-value="1132"
               data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6566-3863-4137-b639-613731613631/02.svg'
                                      imgfield='tn_img_1663882768779'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831657737753990' data-elem-id='1657737753990'
               data-elem-type='text' data-field-top-value="246" data-field-left-value="30" data-field-width-value="611"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="100" data-field-left-res-320-value="22"
               data-field-width-res-320-value="274" data-field-top-res-480-value="145"
               data-field-left-res-480-value="30"
               data-field-width-res-480-value="461" data-field-top-res-640-value="194"
               data-field-left-res-640-value="30"
               data-field-top-res-960-value="246" data-field-left-res-960-value="30"
          ><h2 class='tn-atom' field='tn_text_1657737753990'>ВСЕГДА ВПЕРЕДИ КОНКУРЕНТОВ!</h2></div>
          <div class='t396__elem tn-elem tn-elem__5136976831663883180299' data-elem-id='1663883180299'
               data-elem-type='image' data-field-top-value="1196" data-field-left-value="0" data-field-width-value="640"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="640" data-field-fileheight-value="800"
               data-field-top-res-320-value="1115" data-field-left-res-320-value="-360" data-field-top-res-480-value="0"
               data-field-left-res-480-value="-80"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6237-3131-4233-a636-613165376537/03.svg'
                                      imgfield='tn_img_1663883180299'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831663883263843' data-elem-id='1663883263843'
               data-elem-type='image' data-field-top-value="1108" data-field-left-value="540"
               data-field-width-value="480"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="480" data-field-fileheight-value="500"
               data-field-top-res-320-value="0" data-field-left-res-320-value="-80" data-field-width-res-320-value="480"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3636-3561-4665-b332-386636343034/Rectangle_28.svg'
                                      imgfield='tn_img_1663883263843'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831661513472361' data-elem-id='1661513472361'
               data-elem-type='image' data-field-top-value="214" data-field-left-value="529"
               data-field-width-value="620"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="2700" data-field-fileheight-value="2700"
               data-field-top-res-320-value="10" data-field-left-res-320-value="170"
               data-field-width-res-320-value="140"
               data-field-top-res-480-value="475" data-field-left-res-480-value="160"
               data-field-width-res-480-value="358"
               data-field-top-res-640-value="432" data-field-left-res-640-value="260"
               data-field-width-res-640-value="425"
               data-field-top-res-960-value="345" data-field-left-res-960-value="420"
               data-field-width-res-960-value="516"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6537-6231-4735-b462-623638353333/Frame_7.svg'
                                      imgfield='tn_img_1661513472361'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064559269' data-elem-id='1662064559269'
               data-elem-type='shape' data-field-top-value="199" data-field-left-value="1179"
               data-field-height-value="6"
               data-field-width-value="6" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="137"
               data-field-left-res-320-value="795" data-field-top-res-480-value="132"
               data-field-left-res-480-value="1081"
               data-field-top-res-640-value="199" data-field-left-res-640-value="1179"
               data-field-top-res-960-value="199"
               data-field-left-res-960-value="1179"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064519689' data-elem-id='1662064519689'
               data-elem-type='shape' data-field-top-value="387" data-field-left-value="609" data-field-height-value="9"
               data-field-width-value="9" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="325"
               data-field-left-res-320-value="225" data-field-top-res-480-value="320"
               data-field-left-res-480-value="511"
               data-field-top-res-640-value="387" data-field-left-res-640-value="609" data-field-top-res-960-value="387"
               data-field-left-res-960-value="609"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064559276' data-elem-id='1662064559276'
               data-elem-type='image' data-field-top-value="196" data-field-left-value="1176"
               data-field-width-value="12"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="134" data-field-left-res-320-value="792" data-field-top-res-480-value="129"
               data-field-left-res-480-value="1078" data-field-top-res-640-value="196"
               data-field-left-res-640-value="1176" data-field-top-res-960-value="196"
               data-field-left-res-960-value="1176"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3330-3835-4231-b965-323730646461/Ellipse_8.svg'
                                      imgfield='tn_img_1662064559276'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064519679' data-elem-id='1662064519679'
               data-elem-type='image' data-field-top-value="382" data-field-left-value="604" data-field-width-value="18"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="320" data-field-left-res-320-value="220" data-field-top-res-480-value="315"
               data-field-left-res-480-value="506" data-field-top-res-640-value="382"
               data-field-left-res-640-value="604"
               data-field-top-res-960-value="382" data-field-left-res-960-value="604"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6464-3238-4638-b862-353231326633/Ellipse_8.svg'
                                      imgfield='tn_img_1662064519679'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064506218' data-elem-id='1662064506218'
               data-elem-type='shape' data-field-top-value="150" data-field-left-value="542" data-field-height-value="6"
               data-field-width-value="6" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="88"
               data-field-left-res-320-value="158" data-field-top-res-480-value="83" data-field-left-res-480-value="444"
               data-field-top-res-640-value="150" data-field-left-res-640-value="542" data-field-top-res-960-value="150"
               data-field-left-res-960-value="542"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064499625' data-elem-id='1662064499625'
               data-elem-type='shape' data-field-top-value="133" data-field-left-value="64" data-field-height-value="14"
               data-field-width-value="14" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="71"
               data-field-left-res-320-value="-320" data-field-top-res-480-value="66"
               data-field-left-res-480-value="-34"
               data-field-top-res-640-value="133" data-field-left-res-640-value="64" data-field-top-res-960-value="133"
               data-field-left-res-960-value="64"
          >
            <div class='tn-atom'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064506212' data-elem-id='1662064506212'
               data-elem-type='image' data-field-top-value="146" data-field-left-value="538" data-field-width-value="12"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="84" data-field-left-res-320-value="154" data-field-top-res-480-value="79"
               data-field-left-res-480-value="440" data-field-top-res-640-value="146"
               data-field-left-res-640-value="538"
               data-field-top-res-960-value="146" data-field-left-res-960-value="538"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6434-3733-4362-b935-633637393565/Ellipse_8.svg'
                                      imgfield='tn_img_1662064506212'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064499613' data-elem-id='1662064499613'
               data-elem-type='image' data-field-top-value="127" data-field-left-value="58" data-field-width-value="27"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="33" data-field-fileheight-value="32"
               data-field-top-res-320-value="65" data-field-left-res-320-value="-326" data-field-top-res-480-value="60"
               data-field-left-res-480-value="-40" data-field-top-res-640-value="127" data-field-left-res-640-value="58"
               data-field-top-res-960-value="127" data-field-left-res-960-value="58"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild3331-3865-4633-a332-643835313266/Ellipse_8.svg'
                                      imgfield='tn_img_1662064499613'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__5136976831662064474165' data-elem-id='1662064474165'
               data-elem-type='image' data-field-top-value="108" data-field-left-value="-120"
               data-field-width-value="1440" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="px" data-field-filewidth-value="1440"
               data-field-fileheight-value="500" data-field-top-res-320-value="46" data-field-left-res-320-value="-504"
               data-field-top-res-480-value="41" data-field-left-res-480-value="-218" data-field-top-res-640-value="108"
               data-field-left-res-640-value="-120" data-field-top-res-960-value="108"
               data-field-left-res-960-value="-120"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6430-3132-4632-a231-303936626166/Group_37.svg'
                                      imgfield='tn_img_1662064474165'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec476658125" class="r t-rec t-rec_pt_0" style="padding-top:0px;background-color:#ffffff; "
         data-animationappear="off" data-record-type="396" data-bg-color="#ffffff"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="476658125" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="565" data-artboard-valign="center" data-artboard-upscale="grid"
        >
          <div class="t396__carrier" data-artboard-recid="476658125"></div>
          <div class="t396__filter" data-artboard-recid="476658125"></div>
          <div class='t396__elem tn-elem tn-elem__4766581251657721103501' data-elem-id='1657721103501'
               data-elem-type='text' data-field-top-value="141" data-field-left-value="10" data-field-width-value="646"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="1205" data-field-left-res-320-value="20"
               data-field-width-res-320-value="319" data-field-top-res-480-value="78" data-field-left-res-480-value="10"
               data-field-width-res-480-value="470" data-field-top-res-640-value="1028"
               data-field-left-res-640-value="0"
               data-field-width-res-640-value="632" data-field-top-res-960-value="101"
               data-field-left-res-960-value="10"
          ><h2 class='tn-atom' field='tn_text_1657721103501'>DSP ALL-BILLBOARDS -<br>удобная платформа для онлайн
            размещения и управления цифровой наружной рекламой по всей стране</h2></div>
          <div class='t396__elem tn-elem tn-elem__4766581251661430394751' data-elem-id='1661430394751'
               data-elem-type='image' data-field-top-value="-4" data-field-left-value="674" data-field-width-value="637"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="600" data-field-fileheight-value="450"
               data-field-top-res-320-value="282" data-field-left-res-320-value="-5"
               data-field-width-res-320-value="330"
               data-field-top-res-480-value="228" data-field-left-res-480-value="94" data-field-top-res-640-value="264"
               data-field-left-res-640-value="240" data-field-width-res-640-value="399"
               data-field-top-res-960-value="186"
               data-field-left-res-960-value="529" data-field-width-res-960-value="466"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6633-3135-4361-b261-633364653538/block2.svg'
                                      imgfield='tn_img_1661430394751'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4766581251663868142874' data-elem-id='1663868142874'
               data-elem-type='text' data-field-top-value="1025" data-field-left-value="40" data-field-width-value="725"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="975" data-field-left-res-320-value="10"
               data-field-width-res-320-value="319" data-field-top-res-480-value="1070"
               data-field-left-res-480-value="10"
               data-field-width-res-480-value="470" data-field-top-res-640-value="78" data-field-left-res-640-value="10"
               data-field-width-res-640-value="632" data-field-top-res-960-value="943" data-field-left-res-960-value="0"
          >
            <div class='tn-atom' field='tn_text_1663868142874'>DSP ALL-BILLBOARDS -<br>удобная платформа для онлайн
              размещения и управления цифровой наружной рекламой <br>по всей стране
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4766581251663868231148' data-elem-id='1663868231148'
               data-elem-type='text' data-field-top-value="1341" data-field-left-value="40" data-field-width-value="725"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="69" data-field-left-res-320-value="20"
               data-field-width-res-320-value="280" data-field-top-res-480-value="1275"
               data-field-left-res-480-value="20"
               data-field-width-res-480-value="470" data-field-top-res-640-value="1300"
               data-field-left-res-640-value="20"
               data-field-width-res-640-value="632" data-field-top-res-960-value="1257"
               data-field-left-res-960-value="0"
          >
            <div class='tn-atom' field='tn_text_1663868231148'>DSP ALL-BILLBOARDS -<br>удобная платформа<br> для онлайн
              размещения<br> и управления цифровой наружной рекламой<br> по всей стране
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec493766656" class="r t-rec" style=" " data-record-type="215"><a name="preim" style="font-size:0;"></a>
    </div>
    <div id="rec476469406" class="r t-rec t-rec_pt_0 t-screenmin-980px" style="padding-top:0px; "
         data-animationappear="off" data-record-type="396" data-screen-min="980px"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="476469406" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="1" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-ovrflw="visible" data-artboard-height-res-960="1"
        >
          <div class="t396__carrier" data-artboard-recid="476469406"></div>
          <div class="t396__filter" data-artboard-recid="476469406"></div>
          <div class='t396__elem tn-elem tn-elem__4764694061657721103501' data-elem-id='1657721103501'
               data-elem-type='text' data-field-top-value="8" data-field-left-value="10" data-field-width-value="801"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-960-value="6" data-field-left-res-960-value="10"
               data-field-width-res-960-value="434"
          >
            <div class='tn-atom' field='tn_text_1657721103501'>Почему ALL-BILLBOARDS?</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4764694061659695766039' data-elem-id='1659695766039'
               data-elem-type='shape' data-field-top-value="148" data-field-left-value="-27"
               data-field-height-value="380"
               data-field-width-value="571" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-960-value="146"
               data-field-left-res-960-value="-32" data-field-height-res-960-value="333"
               data-field-width-res-960-value="500"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6533-3834-4730-b239-346238343239/hr_08_Converted.png"
                 style="background-image:url('images/tild6533-3834-4730-b239-346238343239/hr_08_Converted.png');"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec493762246" class="r t-rec t-rec_pb_15 t-screenmax-980px" style="padding-bottom:15px; "
         data-animationappear="off" data-record-type="396" data-screen-max="980px"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="493762246" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="100" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-640="150"
        >
          <div class="t396__carrier" data-artboard-recid="493762246"></div>
          <div class="t396__filter" data-artboard-recid="493762246"></div>
          <div class='t396__elem tn-elem tn-elem__4937622461663869018249' data-elem-id='1663869018249'
               data-elem-type='text' data-field-top-value="8" data-field-left-value="10" data-field-width-value="801"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="6" data-field-left-res-320-value="19"
               data-field-width-res-320-value="280" data-field-top-res-480-value="6" data-field-left-res-480-value="10"
               data-field-top-res-640-value="6" data-field-left-res-640-value="10" data-field-top-res-960-value="7"
               data-field-left-res-960-value="10"
          ><h2 class='tn-atom' field='tn_text_1663869018249'>Почему <br>ALL-BILLBOARDS?</h2></div>
        </div>
      </div>
    </div>
    <div id="rec476445268" class="r t-rec t-rec_pt_0 t-rec_pb_0" style="padding-top:0px;padding-bottom:0px; "
         data-animationappear="off" data-record-type="849"><!-- t849-->
      <div class="t849">
        <div class="t-container">
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion1_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1531218331971"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Федеральный охват</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion1_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild6261-6133-4838-b831-303763383338/Vector.svg"
                                                      imgfield="li_img__1531218331971"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1531218331971"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Крупнейшая база поверхностей.
                      Запускайте и управляйте кампаниями на тысячах конструкций по всей России.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion2_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1531218535874"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Быстрый старт</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion2_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild3362-3766-4537-b865-363632343763/akar-icons_settings-.svg"
                                                      imgfield="li_img__1531218535874"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1531218535874"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Старт и остановка рекламы всего
                      за
                      несколько минут. Гибкие настройки кампании.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion3_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1659687677121"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Низкая стоимость</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion3_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild6331-3636-4337-b564-343231323563/simple-icons_leaderp.svg"
                                                      imgfield="li_img__1659687677121"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1659687677121"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Цена показа от 40 копеек. Бюджет
                      кампании от 1000 рублей.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion4_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1531218625139"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Без посредников</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion4_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild3937-3637-4462-b839-343739626263/Vector.svg"
                                                      imgfield="li_img__1531218625139"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1531218625139"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Вы самостоятельно покупаете
                      наружную рекламу у владельцев поверхностей, определяете время, количество и стоимость показов
                      своей
                      рекламы.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion5_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1531218700960"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Онлайн статистика</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion5_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild6165-3530-4133-b538-353936303664/wpf_statistics_2.svg"
                                                      imgfield="li_img__1531218700960"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1531218700960"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Вы всегда в курсе, сколько и где
                      откручено показов, и можете легко корректировать кампанию.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion6_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1659686100879"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Ротация креативов</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion6_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild6566-3861-4733-a134-646238373930/ant-design_field-tim.svg"
                                                      imgfield="li_img__1659686100879"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1659686100879"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Показывайте разные сюжеты днем и
                      ночью, в будни и выходные. Таргетируйте по погодным условиям. Оперативно информируйте об акциях и
                      распродажах!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion7_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1659686176517"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Умные алгоритмы</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion7_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild6566-3235-4734-b834-323863633064/fluent_arrow-trendin.svg"
                                                      imgfield="li_img__1659686176517"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1659686176517"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Выбор разных стратегий торгов
                      экономит до 40% бюджета без ущерба для эффективности!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion8_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1659686190070"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Таргетинг по погодным условиям</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion8_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/weather.svg"
                                                      imgfield="li_img__1659686190070"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1659686190070"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Запускаем креативы на разную погоду<br/>Источник: Gismeteo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-col t-col_6 t-prefix_6">
            <div class="t849__accordion" data-accordion="false">
              <div class="t849__wrapper">
                <div class="t849__header " style="border-top: 1px solid #eee;">
                  <button type="button"
                          class="t849__trigger-button"
                          aria-controls="accordion9_476445268"
                          aria-expanded="false"
                          style="padding-top:15px;padding-bottom:15px;"><span class="t849__title t-name t-name_xl"
                                                                              field="li_title__1659686212960"
                                                                              style="font-size:25px;font-weight:300;font-family:'Ubuntu';">Независимый аудит</span><span
                      class="t849__icon"><span class="t849__lines"><svg role="presentation" focusable="false"
                                                                        width="24px"
                                                                        height="24px" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: transparent;"></span></span><span
                      class="t849__icon t849__icon-hover"><span class="t849__lines"><svg role="presentation"
                                                                                         focusable="false" width="24px"
                                                                                         height="24px"
                                                                                         viewBox="0 0 24 24"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         xmlns:xlink="http://www.w3.org/1999/xlink"><g
                      stroke="none" stroke-width="2px" fill="none" fill-rule="evenodd" stroke-linecap="square"><g
                      transform="translate(1.000000, 1.000000)" stroke="#ffffff"><path d="M0,11 L22,11"></path><path
                      d="M11,0 L11,22"></path></g></g></svg></span><span class="t849__circle"
                                                                         style="background-color: #3aa3c6"></span></span>
                  </button>
                </div>
                <div class="t849__content"
                     id="accordion9_476445268"
                     hidden>
                  <div class="t849__textwrapper"><img class="t849__img t-img"
                                                      src="images/tild3263-3236-4633-b632-346235363039/Vector.svg"
                                                      imgfield="li_img__1659686212960"
                                                      style="max-width: 40px;" alt="">
                    <div class="t849__text t-descr t-descr_sm" field="li_descr__1659686212960"
                         style="font-size:18px;font-weight:300;font-family:'TTHoves';">Выходы рекламы подтверждаются
                      самым
                      авторитетным в отрасли медиаизмерителем, компанией AdMetrix.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t849__border" style="height: 1px; background-color: #eee;"></div>
          </div>
        </div>
      </div>

    </div>
    <div id="rec493766872" class="r t-rec" style=" " data-record-type="215"><a name="work" style="font-size:0;"></a>
    </div>
    <div id="rec468703498" class="r t-rec t-rec_pt_45" style="padding-top:45px; " data-animationappear="off"
         data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468703498" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="191" data-artboard-valign="center" data-artboard-upscale="grid"
        >
          <div class="t396__carrier" data-artboard-recid="468703498"></div>
          <div class="t396__filter" data-artboard-recid="468703498"></div>
          <div class='t396__elem tn-elem tn-elem__4687034981657721103501' data-elem-id='1657721103501'
               data-elem-type='text' data-field-top-value="101" data-field-left-value="10" data-field-width-value="801"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="405" data-field-left-res-320-value="0"
               data-field-width-res-320-value="310" data-field-top-res-480-value="410" data-field-left-res-480-value="0"
               data-field-width-res-480-value="482" data-field-top-res-640-value="97" data-field-left-res-640-value="10"
               data-field-top-res-960-value="101" data-field-left-res-960-value="10"
          ><h2 class='tn-atom' field='tn_text_1657721103501'>Запуск кампаниЙ в наружной рекламе всего за 3 шага!</h2>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687034981657721103586' data-elem-id='1657721103586'
               data-elem-type='image' data-field-top-value="519" data-field-left-value="1243"
               data-field-width-value="34"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="36" data-field-fileheight-value="71"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6133-6533-4639-a666-643530346264/xcv.svg'
                                      imgfield='tn_img_1657721103586'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687034981659710723819' data-elem-id='1659710723819'
               data-elem-type='shape' data-field-top-value="19" data-field-left-value="-193"
               data-field-height-value="155.99" data-field-width-value="1586" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-480-value="13" data-field-left-res-480-value="-193"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3733-3765-4566-a664-383534363731/Group_56.svg"
                 style="background-image:url('images/tild3733-3765-4566-a664-383534363731/Group_56.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687034981663868786220' data-elem-id='1663868786220'
               data-elem-type='text' data-field-top-value="740" data-field-left-value="50" data-field-width-value="801"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="88" data-field-left-res-320-value="20"
               data-field-width-res-320-value="281" data-field-top-res-480-value="83" data-field-left-res-480-value="10"
               data-field-width-res-480-value="482" data-field-top-res-640-value="750"
               data-field-left-res-640-value="11"
          >
            <div class='tn-atom' field='tn_text_1663868786220'>Запуск кампаниЙ <br>в наружной рекламе всего за 3 шага!
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec458821553" class="r t-rec t-rec_pt_0 t-rec_pb_30"
         style="padding-top:0px;padding-bottom:30px;background-color:#ffffff; " data-record-type="549"
         data-bg-color="#ffffff"><!-- T549 -->
      <div class="t549">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div style="font-size: 36px;" data-customstyle="yes"></div>
              </div>
              <div class="t-section__descr t-descr t-descr_xl t-margin_auto" field="bdescr">
                <div style="font-size: 26px;" data-customstyle="yes"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="t-container">
          <div class="t549__col t-col t-col_4">
            <div class="t549__imgwrapper t-align_center">
              <div class="t549__bgimg t-margin_auto t-bgimg"
                   data-original="images/tild3237-3963-4461-b561-396665373531/01.svg"
                   bgimgfield="img"
                   style="width: 200px; height: 200px; background-image: url('images/tild3237-3963-4461-b561-396665373531/01.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image" content="images/tild3237-3963-4461-b561-396665373531/01.svg">
              </div>
            </div>
            <div class="t549__textwrapper t-align_center">
              <div class="t549__text t-text t-text_xs"
                   style="color:#000000;font-size:25px;line-height:1.3;font-weight:300;padding-top:8px;font-family:'TTHoves';"
                   field="descr">Выбирайте поверхности <br/>на карте
              </div>
            </div>
          </div>
          <div class="t549__col t-col t-col_4">
            <div class="t549__imgwrapper t-align_center">
              <div class="t549__arrow"><img class="t-img"
                                            src="images/tild3134-3233-4364-b164-393236336637/photo.svg"/>
              </div>
              <div class="t549__bgimg t-margin_auto t-bgimg"
                   data-original="images/tild3337-6662-4134-a632-653161633262/02.svg"
                   bgimgfield="img2"
                   style="width: 200px; height: 200px; background-image: url('images/tild3337-6662-4134-a632-653161633262/02.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image" content="images/tild3337-6662-4134-a632-653161633262/02.svg">
              </div>
            </div>
            <div class="t549__textwrapper t-align_center">
              <div class="t549__text t-text t-text_xs"
                   style="color:#000000;font-size:25px;line-height:1.3;font-weight:300;padding-top:8px;font-family:'TTHoves';"
                   field="descr2">Задавайте дни и время показа рекламы, бюджет и стратегию
              </div>
            </div>
          </div>
          <div class="t549__col t-col t-col_4">
            <div class="t549__imgwrapper t-align_center">
              <div class="t549__arrow"><img class="t-img"
                                            src="images/tild3134-3233-4364-b164-393236336637/photo.svg"/>
              </div>
              <div class="t549__bgimg t-margin_auto t-bgimg"
                   data-original="images/tild3939-3536-4662-b934-613531326663/03.svg"
                   bgimgfield="img3"
                   style="width: 200px; height: 200px; background-image: url('images/tild3939-3536-4662-b934-613531326663/03.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image" content="images/tild3939-3536-4662-b934-613531326663/03.svg">
              </div>
            </div>
            <div class="t549__textwrapper t-align_center">
              <div class="t549__text t-text t-text_xs"
                   style="color:#000000;font-size:25px;line-height:1.3;font-weight:300;padding-top:8px;font-family:'TTHoves';"
                   field="descr3">Регистрируйтесь<br/>и загружайте креатив
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468705458" class="r t-rec t-rec_pt_45" style="padding-top:45px; " data-animationappear="off"
         data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468705458" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="100" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="50"
        >
          <div class="t396__carrier" data-artboard-recid="468705458"></div>
          <div class="t396__filter" data-artboard-recid="468705458"></div>
          <div class='t396__elem tn-elem tn-elem__4687054581470210224069' data-elem-id='1470210224069'
               data-elem-type='shape' data-field-top-value="12" data-field-left-value="392"
               data-field-height-value="76.31" data-field-width-value="417" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-320-value="0" data-field-left-res-320-value="34" data-field-height-res-320-value="47"
               data-field-width-res-320-value="253" data-field-top-res-480-value="27"
               data-field-left-res-480-value="114"
               data-field-height-res-480-value="47" data-field-width-res-480-value="253"
               data-field-top-res-640-value="21"
               data-field-left-res-640-value="164" data-field-height-res-640-value="58"
               data-field-width-res-640-value="312" data-field-top-res-960-value="20"
               data-field-left-res-960-value="316"
               data-field-height-res-960-value="60" data-field-width-res-960-value="329"
          ><a class='tn-atom t-bgimg'
              href="/DSP-Abb.pdf?v=1" target="_blank"
              data-original="images/tild3732-6132-4037-b764-653366373132/Button.png"
              style="background-image:url('images/tild3732-6132-4037-b764-653366373132/Button.png');"
          ></a></div>
        </div>
      </div>
    </div>
    <div id="rec493766925" class="r t-rec" style=" " data-record-type="215"><a name="agency" style="font-size:0;"></a>
    </div>
    <div id="rec468729289" class="r t-rec t-rec_pt_45 t-rec_pb_30" style="padding-top:45px;padding-bottom:30px; "
         data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468729289" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="241" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="181"
        >
          <div class="t396__carrier" data-artboard-recid="468729289"></div>
          <div class="t396__filter" data-artboard-recid="468729289"></div>
          <div class='t396__elem tn-elem tn-elem__4687292891657721103496' data-elem-id='1657721103496'
               data-elem-type='text' data-field-top-value="101" data-field-left-value="10" data-field-width-value="703"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="36" data-field-left-res-320-value="20"
               data-field-width-res-320-value="280" data-field-top-res-480-value="101"
               data-field-left-res-480-value="10"
               data-field-width-res-480-value="458" data-field-top-res-640-value="101"
               data-field-left-res-640-value="10"
               data-field-width-res-640-value="631" data-field-top-res-960-value="101"
               data-field-left-res-960-value="10"
          ><h2 class='tn-atom' field='tn_text_1657721103496'>ПОЧЕМУ АГЕНТСТВА ВЫБИРАЮТ <br>DSP ALL-BILLBOARDS:</h2>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687292891657721103586' data-elem-id='1657721103586'
               data-elem-type='image' data-field-top-value="519" data-field-left-value="1243"
               data-field-width-value="34"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="36" data-field-fileheight-value="71"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6133-6533-4639-a666-643530346264/xcv.svg'
                                      imgfield='tn_img_1657721103586'></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687292891657729965877' data-elem-id='1657729965877'
               data-elem-type='shape' data-field-top-value="65" data-field-left-value="-210"
               data-field-height-value="171"
               data-field-width-value="1543" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="0"
               data-field-left-res-320-value="-320" data-field-top-res-480-value="65"
               data-field-left-res-480-value="-320"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3131-3439-4566-b936-326330363262/vb.svg"
                 style="background-image:url('images/tild3131-3439-4566-b936-326330363262/vb.svg');"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468710911" class="r t-rec" style=" " data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468710911" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="1041" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="1033" data-artboard-height-res-480="833" data-artboard-height-res-640="950"
             data-artboard-height-res-960="990"
        >
          <div class="t396__carrier" data-artboard-recid="468710911"></div>
          <div class="t396__filter" data-artboard-recid="468710911"></div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723740004' data-elem-id='1657723740004'
               data-elem-type='shape' data-field-top-value="693" data-field-left-value="211"
               data-field-height-value="70"
               data-field-width-value="70" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="773"
               data-field-left-res-320-value="57" data-field-top-res-480-value="625" data-field-left-res-480-value="54"
               data-field-top-res-640-value="659" data-field-left-res-640-value="-2" data-field-left-res-960-value="172"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3537-3962-4365-a536-653534316436/Group_105_1.png"
                 style="background-image:url('images/tild3537-3962-4365-a536-653534316436/Group_105_1.png');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723712789' data-elem-id='1657723712789'
               data-elem-type='shape' data-field-top-value="518" data-field-left-value="643"
               data-field-height-value="70"
               data-field-width-value="70" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="560"
               data-field-left-res-320-value="54" data-field-top-res-480-value="487" data-field-left-res-480-value="53"
               data-field-top-res-640-value="489" data-field-left-res-640-value="354" data-field-top-res-960-value="518"
               data-field-left-res-960-value="534"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3138-3131-4739-b932-353533333566/Group_103_1.svg"
                 style="background-image:url('images/tild3138-3131-4739-b932-353533333566/Group_103_1.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723669717' data-elem-id='1657723669717'
               data-elem-type='shape' data-field-top-value="336" data-field-left-value="208"
               data-field-height-value="75"
               data-field-width-value="75" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="379"
               data-field-left-res-320-value="54" data-field-top-res-480-value="332" data-field-left-res-480-value="54"
               data-field-top-res-640-value="334" data-field-left-res-640-value="-3" data-field-top-res-960-value="336"
               data-field-left-res-960-value="157"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3362-3031-4638-a636-666332383864/Group_102_1.svg"
                 style="background-image:url('images/tild3362-3031-4638-a636-666332383864/Group_102_1.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723646154' data-elem-id='1657723646154'
               data-elem-type='shape' data-field-top-value="180" data-field-left-value="655"
               data-field-height-value="49"
               data-field-width-value="45" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="200"
               data-field-left-res-320-value="70" data-field-top-res-480-value="186" data-field-left-res-480-value="70"
               data-field-top-res-640-value="163" data-field-left-res-640-value="365" data-field-top-res-960-value="180"
               data-field-left-res-960-value="534"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3432-3130-4632-a436-356531313031/Group_50.svg"
                 style="background-image:url('images/tild3432-3130-4632-a436-356531313031/Group_50.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723577007' data-elem-id='1657723577007'
               data-elem-type='shape' data-field-top-value="21" data-field-left-value="216" data-field-height-value="52"
               data-field-width-value="38" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="14"
               data-field-left-res-320-value="65" data-field-top-res-480-value="24" data-field-left-res-480-value="70"
               data-field-height-res-480-value="50" data-field-width-res-480-value="37"
               data-field-top-res-640-value="20"
               data-field-left-res-640-value="10" data-field-top-res-960-value="21" data-field-left-res-960-value="172"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6265-3164-4666-b436-633635373339/Vector.svg"
                 style="background-image:url('images/tild6265-3164-4666-b436-633635373339/Vector.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723205769' data-elem-id='1657723205769'
               data-elem-type='text' data-field-top-value="762" data-field-left-value="222" data-field-width-value="325"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="836" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="680"
               data-field-left-res-480-value="67"
               data-field-width-res-480-value="370" data-field-top-res-640-value="723" data-field-left-res-640-value="8"
               data-field-width-res-640-value="240" data-field-top-res-960-value="762"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1657723205769'>Освободите время для аналитики!</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723205762' data-elem-id='1657723205762'
               data-elem-type='text' data-field-top-value="836" data-field-left-value="222" data-field-width-value="341"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="894" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="714"
               data-field-left-res-480-value="66"
               data-field-width-res-480-value="405" data-field-top-res-640-value="782" data-field-left-res-640-value="9"
               data-field-width-res-640-value="284" data-field-top-res-960-value="836"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="280"
          >
            <div class='tn-atom' field='tn_text_1657723205762'>
              <ul>
                <li>Умные стратегии покупки берут рутину на себя и экономят до 40% бюджета</li>
                <li>У вас больше не болит голова, как получить фотоотчеты. Наша система загружает их автоматически,</li>
              </ul>
              <span style="color: rgb(255, 255, 255);">.....</span>они всегда в вашем кабинете!<br><br></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723135490' data-elem-id='1657723135490'
               data-elem-type='text' data-field-top-value="634" data-field-left-value="655" data-field-width-value="365"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="685" data-field-left-res-320-value="70"
               data-field-width-res-320-value="241" data-field-top-res-480-value="427"
               data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="615"
               data-field-left-res-640-value="366"
               data-field-width-res-640-value="265" data-field-top-res-960-value="634"
               data-field-left-res-960-value="534"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1657723135490'>
              <ul>
                <li>Персональные кабинеты клиентов</li>
                <li>Возможность самостоятельного управления кампаниями</li>
                <li>Статистика в режиме реального времени</li>
              </ul>
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723135483' data-elem-id='1657723135483'
               data-elem-type='text' data-field-top-value="584" data-field-left-value="655" data-field-width-value="387"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="627" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="393"
               data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="549"
               data-field-left-res-640-value="366"
               data-field-width-res-640-value="240" data-field-top-res-960-value="584"
               data-field-left-res-960-value="534"
               data-field-width-res-960-value="309"
          >
            <div class='tn-atom' field='tn_text_1657723135483'>Максимум сервиса для клиента!</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474628077848' data-elem-id='1474628077848'
               data-elem-type='text' data-field-top-value="408" data-field-left-value="222" data-field-width-value="325"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="444" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="544"
               data-field-left-res-480-value="69"
               data-field-width-res-480-value="370" data-field-top-res-640-value="397"
               data-field-left-res-640-value="10"
               data-field-width-res-640-value="240" data-field-top-res-960-value="408"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1474628077848'>Решайте сами сколько зарабатывать!</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474628075709' data-elem-id='1474628075709'
               data-elem-type='text' data-field-top-value="482" data-field-left-value="222" data-field-width-value="325"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="503" data-field-left-res-320-value="70"
               data-field-width-res-320-value="241" data-field-top-res-480-value="577"
               data-field-left-res-480-value="67"
               data-field-width-res-480-value="370" data-field-top-res-640-value="455"
               data-field-left-res-640-value="10"
               data-field-width-res-640-value="267" data-field-top-res-960-value="482"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1474628075709'>Устанавливайте индивидуальные наценки для разных клиентов
              <br>и операторов!
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474627943918' data-elem-id='1474627943918'
               data-elem-type='text' data-field-top-value="75" data-field-left-value="222" data-field-width-value="359"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="66" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="76" data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="74" data-field-left-res-640-value="9"
               data-field-width-res-640-value="258" data-field-top-res-960-value="75"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1474627943918'>Лучшие условия на рынке</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474627941995' data-elem-id='1474627941995'
               data-elem-type='text' data-field-top-value="125" data-field-left-value="222" data-field-width-value="325"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="100" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="110"
               data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="115" data-field-left-res-640-value="9"
               data-field-width-res-640-value="240" data-field-top-res-960-value="117"
               data-field-left-res-960-value="172"
               data-field-width-res-960-value="250"
          >
            <div class='tn-atom' field='tn_text_1474627941995'>
              <ul>
                <li>Прямое участие в RTB-аукционах владельцев поверхностей</li>
                <li>Самая низкая на рынке комиссия системы, всего 3%</li>
                <li>Отсутствие скрытых наценок</li>
              </ul>
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474627361375' data-elem-id='1474627361375'
               data-elem-type='text' data-field-top-value="282" data-field-left-value="655" data-field-width-value="359"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="308" data-field-left-res-320-value="70"
               data-field-width-res-320-value="232" data-field-top-res-480-value="271"
               data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="271"
               data-field-left-res-640-value="366"
               data-field-width-res-640-value="263" data-field-top-res-960-value="274"
               data-field-left-res-960-value="534"
               data-field-width-res-960-value="277"
          >
            <div class='tn-atom' field='tn_text_1474627361375'>Модуль DSP устанавливается на ваш сайт<br>и брендируется
              в
              корпоративном стиле агентства. Отчеты для клиентов содержат только ваш логотип и контакты
            </div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111470210011265' data-elem-id='1470210011265'
               data-elem-type='text' data-field-top-value="232" data-field-left-value="655" data-field-width-value="372"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="252" data-field-left-res-320-value="70"
               data-field-width-res-320-value="230" data-field-top-res-480-value="237"
               data-field-left-res-480-value="70"
               data-field-width-res-480-value="370" data-field-top-res-640-value="214"
               data-field-left-res-640-value="365"
               data-field-width-res-640-value="183" data-field-top-res-960-value="232"
               data-field-left-res-960-value="534"
               data-field-width-res-960-value="276"
          >
            <div class='tn-atom' field='tn_text_1470210011265'>Это действительно ваша DSP</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657724525189' data-elem-id='1657724525189'
               data-elem-type='shape' data-field-top-value="59" data-field-left-value="599"
               data-field-height-value="700"
               data-field-width-value="2" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="75"
               data-field-left-res-320-value="39" data-field-height-res-320-value="787"
               data-field-width-res-320-value="2"
               data-field-top-res-480-value="77" data-field-left-res-480-value="33"
               data-field-height-res-480-value="600"
               data-field-top-res-640-value="59" data-field-left-res-640-value="319" data-field-top-res-960-value="59"
               data-field-left-res-960-value="479"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3938-3137-4837-a632-323839353838/photo.svg"
                 style="background-image:url('images/tild3938-3137-4837-a632-323839353838/photo.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723101749' data-elem-id='1657723101749'
               data-elem-type='text' data-field-top-value="589" data-field-left-value="577" data-field-width-value="48"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="636" data-field-left-res-320-value="16"
               data-field-top-res-480-value="533" data-field-left-res-480-value="10" data-field-top-res-640-value="565"
               data-field-left-res-640-value="297" data-field-top-res-960-value="589"
               data-field-left-res-960-value="456"
          >
            <div class='tn-atom' field='tn_text_1657723101749'>4</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723101741' data-elem-id='1657723101741'
               data-elem-type='shape' data-field-top-value="579" data-field-left-value="576"
               data-field-height-value="50"
               data-field-width-value="50" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="631"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="40"
               data-field-top-res-480-value="523" data-field-left-res-480-value="9" data-field-height-res-480-value="50"
               data-field-width-res-480-value="50" data-field-top-res-640-value="555"
               data-field-left-res-640-value="296"
               data-field-top-res-960-value="579" data-field-left-res-960-value="455"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6537-3539-4032-b865-646633643435/Ellipse_9.png"
                 style="background-image:url('images/tild6537-3539-4032-b865-646633643435/Ellipse_9.png');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723179196' data-elem-id='1657723179196'
               data-elem-type='text' data-field-top-value="765" data-field-left-value="577" data-field-width-value="48"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="844" data-field-left-res-320-value="16"
               data-field-top-res-480-value="677" data-field-left-res-480-value="10" data-field-top-res-640-value="733"
               data-field-left-res-640-value="297" data-field-top-res-960-value="766"
               data-field-left-res-960-value="456"
          >
            <div class='tn-atom' field='tn_text_1657723179196'>5</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111657723179187' data-elem-id='1657723179187'
               data-elem-type='shape' data-field-top-value="755" data-field-left-value="576"
               data-field-height-value="50"
               data-field-width-value="50" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="839"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="40"
               data-field-top-res-480-value="667" data-field-left-res-480-value="9" data-field-height-res-480-value="50"
               data-field-width-res-480-value="50" data-field-top-res-640-value="723"
               data-field-left-res-640-value="296"
               data-field-top-res-960-value="756" data-field-left-res-960-value="455"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6336-6566-4365-b835-646533636334/Ellipse_9.png"
                 style="background-image:url('images/tild6336-6566-4365-b835-646533636334/Ellipse_9.png');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111480499364298' data-elem-id='1480499364298'
               data-elem-type='text' data-field-top-value="413" data-field-left-value="577" data-field-width-value="48"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="452" data-field-left-res-320-value="16"
               data-field-top-res-480-value="388" data-field-left-res-480-value="10" data-field-top-res-640-value="397"
               data-field-left-res-640-value="297" data-field-top-res-960-value="413"
               data-field-left-res-960-value="456"
          >
            <div class='tn-atom' field='tn_text_1480499364298'>3</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474628174920' data-elem-id='1474628174920'
               data-elem-type='shape' data-field-top-value="403" data-field-left-value="576"
               data-field-height-value="50"
               data-field-width-value="50" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="447"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="40"
               data-field-top-res-480-value="378" data-field-left-res-480-value="9" data-field-height-res-480-value="50"
               data-field-width-res-480-value="50" data-field-top-res-640-value="387"
               data-field-left-res-640-value="296"
               data-field-top-res-960-value="403" data-field-left-res-960-value="455"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3638-6163-4632-b862-376536313735/Ellipse_9.png"
                 style="background-image:url('images/tild3638-6163-4632-b862-376536313735/Ellipse_9.png');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111480499355677' data-elem-id='1480499355677'
               data-elem-type='text' data-field-top-value="237" data-field-left-value="577" data-field-width-value="48"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="261" data-field-left-res-320-value="16"
               data-field-top-res-480-value="233" data-field-left-res-480-value="10" data-field-top-res-640-value="229"
               data-field-left-res-640-value="297" data-field-top-res-960-value="237"
               data-field-left-res-960-value="456"
          >
            <div class='tn-atom' field='tn_text_1480499355677'>2</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474628173182' data-elem-id='1474628173182'
               data-elem-type='shape' data-field-top-value="227" data-field-left-value="576"
               data-field-height-value="50"
               data-field-width-value="50" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="256"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="40"
               data-field-top-res-480-value="223" data-field-left-res-480-value="9" data-field-height-res-480-value="50"
               data-field-width-res-480-value="50" data-field-top-res-640-value="219"
               data-field-left-res-640-value="296"
               data-field-top-res-960-value="227" data-field-left-res-960-value="455"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3035-3831-4461-a630-323538363539/Ellipse_9.png"
                 style="background-image:url('images/tild3035-3831-4461-a630-323538363539/Ellipse_9.png');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111480499191307' data-elem-id='1480499191307'
               data-elem-type='text' data-field-top-value="61" data-field-left-value="576" data-field-width-value="48"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="65" data-field-left-res-320-value="16"
               data-field-top-res-480-value="72" data-field-left-res-480-value="9" data-field-top-res-640-value="61"
               data-field-left-res-640-value="297" data-field-top-res-960-value="61" data-field-left-res-960-value="456"
          >
            <div class='tn-atom' field='tn_text_1480499191307'>1</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687109111474627416586' data-elem-id='1474627416586'
               data-elem-type='shape' data-field-top-value="51" data-field-left-value="575" data-field-height-value="50"
               data-field-width-value="50" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="60"
               data-field-left-res-320-value="19" data-field-height-res-320-value="40"
               data-field-width-res-320-value="40"
               data-field-top-res-480-value="62" data-field-left-res-480-value="8" data-field-height-res-480-value="50"
               data-field-width-res-480-value="50" data-field-top-res-640-value="51" data-field-left-res-640-value="296"
               data-field-top-res-960-value="51" data-field-left-res-960-value="455"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3837-6237-4661-b666-303466303262/Ellipse_9.png"
                 style="background-image:url('images/tild3837-6237-4661-b666-303466303262/Ellipse_9.png');"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec460292925" class="r t-rec" style=" " data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="460292925" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="340" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="246" data-artboard-height-res-480="359" data-artboard-height-res-640="378"
             data-artboard-height-res-960="377"
        >
          <div class="t396__carrier" data-artboard-recid="460292925"></div>
          <div class="t396__filter" data-artboard-recid="460292925"></div>
          <div class='t396__elem tn-elem tn-elem__4602929251479129336218' data-elem-id='1479129336218'
               data-elem-type='text' data-field-top-value="80" data-field-left-value="0" data-field-width-value="1160"
               data-field-axisy-value="top" data-field-axisx-value="center" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="19" data-field-left-res-320-value="0"
               data-field-width-res-320-value="300" data-field-top-res-480-value="60" data-field-left-res-480-value="0"
               data-field-width-res-480-value="460" data-field-top-res-640-value="60" data-field-left-res-640-value="0"
               data-field-width-res-640-value="620" data-field-top-res-960-value="65" data-field-left-res-960-value="0"
               data-field-width-res-960-value="940"
          >
            <div class='tn-atom' field='tn_text_1479129336218'>ОСТАЛИСЬ ВОПРОСЫ?</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4602929251657724958302' data-elem-id='1657724958302'
               data-elem-type='shape' data-field-top-value="182" data-field-left-value="436"
               data-field-height-value="76"
               data-field-width-value="329" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="85"
               data-field-left-res-320-value="35" data-field-top-res-480-value="182" data-field-left-res-480-value="115"
               data-field-height-res-480-value="58" data-field-width-res-480-value="251"
               data-field-top-res-640-value="182" data-field-left-res-640-value="156" data-field-top-res-960-value="182"
               data-field-left-res-960-value="316"
          ><a class='tn-atom t-bgimg' href="#popup:myform2"
              data-original="images/tild3432-3438-4838-a331-613534346666/Button.png"
              style="background-image:url('images/tild3432-3438-4838-a331-613534346666/Button.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4602929251657725538528' data-elem-id='1657725538528'
               data-elem-type='shape' data-field-top-value="-33" data-field-left-value="-195"
               data-field-height-value="351" data-field-width-value="1689" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-320-value="-130" data-field-left-res-320-value="-282"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6534-3366-4131-a130-636337623633/Group_29.svg"
                 style="background-image:url('images/tild6534-3366-4131-a130-636337623633/Group_29.svg');"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec493767009" class="r t-rec" style=" " data-record-type="215"><a name="operator" style="font-size:0;"></a>
    </div>
    <div id="rec468738420" class="r t-rec t-rec_pt_0 t-rec_pb_60" style="padding-top:0px;padding-bottom:60px; "
         data-animationappear="off" data-record-type="396"><!-- T396 -->
      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468738420" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="251" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="221"
        >
          <div class="t396__carrier" data-artboard-recid="468738420"></div>
          <div class="t396__filter" data-artboard-recid="468738420"></div>
          <div class='t396__elem tn-elem tn-elem__4687384201657721103496' data-elem-id='1657721103496'
               data-elem-type='text' data-field-top-value="101" data-field-left-value="10" data-field-width-value="703"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="33" data-field-left-res-320-value="20"
               data-field-width-res-320-value="280" data-field-top-res-480-value="62" data-field-left-res-480-value="10"
               data-field-width-res-480-value="479" data-field-top-res-640-value="62" data-field-left-res-640-value="10"
               data-field-width-res-640-value="639" data-field-top-res-960-value="101"
               data-field-left-res-960-value="10"
          ><h2 class='tn-atom' field='tn_text_1657721103496'>Почему владельцы рекламных поверхностей подключаются <br>к
            DSP All-billboards:</h2></div>
          <div class='t396__elem tn-elem tn-elem__4687384201657721103586' data-elem-id='1657721103586'
               data-elem-type='image' data-field-top-value="519" data-field-left-value="1243"
               data-field-width-value="34"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="36" data-field-fileheight-value="71"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6133-6533-4639-a666-643530346264/xcv.svg'
                                      imgfield='tn_img_1657721103586'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468744232" class="r t-rec t-rec_pt_0 t-rec_pb_45" style="padding-top:0px;padding-bottom:45px; "
         data-record-type="503"><!-- t503 -->
      <div class="t503">
        <ul role="list" class="t503__container t-card__container t-card__container_inrow3 t-container">
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild3435-3561-4463-b938-653831363336/online_store_1.svg"
                   bgimgfield="li_img__1476978617855"
                   style="width: 80px; height: 80px; background-image: url('images/tild3435-3561-4463-b938-653831363336/online_store_1.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild3435-3561-4463-b938-653831363336/online_store_1.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978617855"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Стимулируем продажи
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978617855"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Ваш непроданный инвентарь
                попадает в компьютеры десятков агентств.<br/>Покупка осуществляется в несколько кликов.
              </div>
            </div>
          </li>
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild3366-3164-4534-b033-356563376666/Tilda_Icons_47dlvr_2.svg"
                   bgimgfield="li_img__1476978621977"
                   style="width: 80px; height: 80px; background-image: url('images/tild3366-3164-4534-b033-356563376666/Tilda_Icons_47dlvr_2.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild3366-3164-4534-b033-356563376666/Tilda_Icons_47dlvr_2.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978621977"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Сокращаем трудозатраты
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978621977"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Вас больше не мучают
                запросами о статусах, ценах и фотоотчетах.
              </div>
            </div>
          </li>
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild6231-6461-4334-b339-333937353865/3st_discount2_1.svg"
                   bgimgfield="li_img__1476978655843"
                   style="width: 80px; height: 80px; background-image: url('images/tild6231-6461-4334-b339-333937353865/3st_discount2_1.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild6231-6461-4334-b339-333937353865/3st_discount2_1.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978655843"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Отсутствует комиссия
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978655843"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Это значит, что все
                деньги
                клиентов доходят до оператора, а не оседают <br/>у посредников.
              </div>
            </div>
          </li>
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild6166-3863-4433-b233-636638643866/Layer_6_1.svg"
                   bgimgfield="li_img__1476978737724"
                   style="width: 80px; height: 80px; background-image: url('images/tild6166-3863-4433-b233-636638643866/Layer_6_1.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild6166-3863-4433-b233-636638643866/Layer_6_1.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978737724"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Гибкое ценообразование
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978737724"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Поднимайте цену показа в
                высокий сезон и снижайте в низкий. И все это мгновенно!
              </div>
            </div>
          </li>
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild6335-3936-4238-b837-396436313636/touragency_group_1.svg"
                   bgimgfield="li_img__1476978755814"
                   style="width: 80px; height: 80px; background-image: url('images/tild6335-3936-4238-b837-396436313636/touragency_group_1.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild6335-3936-4238-b837-396436313636/touragency_group_1.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978755814"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Федеральные бюджеты
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978755814"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Работая с DSP
                All-billboards, даже небольшие локальные операторы без отделов продаж могут получать свою часть
                федеральных бюджетов.
              </div>
            </div>
          </li>
          <li class="t-card__col t-card__col_withoutbtn t-col t-col_4 t-item"
          >
            <div class="t503__content t-align_left">
              <div class="t503__bgimg t-bgimg"
                   data-original="images/tild3761-3135-4361-a434-626634346534/Tilda_Icons_39_IT_ef.svg"
                   bgimgfield="li_img__1476978778074"
                   style="width: 80px; height: 80px; background-image: url('images/tild3761-3135-4361-a434-626634346534/Tilda_Icons_39_IT_ef.svg');"
                   itemscope itemtype="http://schema.org/ImageObject">
                <meta itemprop="image"
                      content="images/tild3761-3135-4361-a434-626634346534/Tilda_Icons_39_IT_ef.svg">
              </div>
              <div class="t-card__title t-name t-name_md" field="li_title__1476978778074"
                   style="color:#353534;font-size:25px;font-weight:400;font-family:'Ubuntu';">Эффективность
              </div>
              <div class="t-card__descr t-descr t-descr_xs" field="li_descr__1476978778074"
                   style="color:#353534;font-size:18px;font-weight:300;font-family:'TTHoves';">Позвольте клиентам
                ротировать креативы без всяких затрат с вашей стороны. <br/>Мы уже все сделали!
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div id="rec468751513" class="r t-rec" style=" " data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468751513" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="345" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="276" data-artboard-height-res-480="359" data-artboard-height-res-640="378"
             data-artboard-height-res-960="377"
        >
          <div class="t396__carrier" data-artboard-recid="468751513"></div>
          <div class="t396__filter" data-artboard-recid="468751513"></div>
          <div class='t396__elem tn-elem tn-elem__4687515131479129336218' data-elem-id='1479129336218'
               data-elem-type='text' data-field-top-value="80" data-field-left-value="0" data-field-width-value="1160"
               data-field-axisy-value="top" data-field-axisx-value="center" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="-2" data-field-left-res-320-value="0"
               data-field-width-res-320-value="300" data-field-top-res-480-value="60" data-field-left-res-480-value="0"
               data-field-width-res-480-value="460" data-field-top-res-640-value="60" data-field-left-res-640-value="0"
               data-field-width-res-640-value="620" data-field-top-res-960-value="65" data-field-left-res-960-value="0"
               data-field-width-res-960-value="940"
          >
            <div class='tn-atom' field='tn_text_1479129336218'>ХОТИТЕ С НАМИ РАБОТАТЬ?</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687515131657724958302' data-elem-id='1657724958302'
               data-elem-type='shape' data-field-top-value="182" data-field-left-value="436"
               data-field-height-value="76"
               data-field-width-value="329" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="111"
               data-field-left-res-320-value="35" data-field-top-res-480-value="182" data-field-left-res-480-value="115"
               data-field-height-res-480-value="58" data-field-width-res-480-value="251"
               data-field-top-res-640-value="182" data-field-left-res-640-value="156" data-field-top-res-960-value="182"
               data-field-left-res-960-value="316"
          ><a class='tn-atom t-bgimg' href="#popup:myform3"
              data-original="images/tild6263-6464-4661-b339-313134616166/Button.png"
              style="background-image:url('images/tild6263-6464-4661-b339-313134616166/Button.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4687515131657725538528' data-elem-id='1657725538528'
               data-elem-type='shape' data-field-top-value="7" data-field-left-value="-184"
               data-field-height-value="333"
               data-field-width-value="1689" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="-65"
               data-field-left-res-320-value="-546" data-field-top-res-480-value="6"
               data-field-left-res-480-value="-487"
               data-field-top-res-640-value="6" data-field-left-res-640-value="-434" data-field-top-res-960-value="6"
               data-field-left-res-960-value="-307"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6662-6437-4464-b236-656464663263/photo.svg"
                 style="background-image:url('images/tild6662-6437-4464-b236-656464663263/photo.svg');"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec481523127" class="r t-rec" style=" " data-animationappear="off" data-record-type="702"><!-- T702 -->
      <div class="t702">
        <div class="t-popup" data-tooltip-hook="#popup:myform"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
             aria-label="Оставить заявку">
          <div class="t-popup__container t-width t-width_6" style="background-color:#ffffff;">
            <div class="t702__wrapper">
              <div class="t702__text-wrapper t-align_center">
                <div class="t702__title t-title t-title_xxs" style="color:#353534;font-size:40px;font-family:'Ubuntu';">
                  Оставить заявку
                </div>
              </div>
              <form id="form481523127" name='form481523127' role="form" action='' method='POST' data-formactiontype="2"
                    data-inputbox=".t-input-group" class="t-form js-form-proccess t-form_inputs-total_4 "
                    data-success-callback="t702_onSuccess">
                <form-component name="Форма шапка"/>
              </form>

            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>


    </div>
    <div id="rec501142876" class="r t-rec" style=" " data-animationappear="off" data-record-type="702"><!-- T702 -->
      <div class="t702">
        <div class="t-popup" data-tooltip-hook="#popup:myform2"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
             aria-label="Оставить заявку">
          <div class="t-popup__container t-width t-width_6" style="background-color:#ffffff;">
            <div class="t702__wrapper">
              <div class="t702__text-wrapper t-align_center">
                <div class="t702__title t-title t-title_xxs" style="color:#353534;font-size:40px;font-family:'Ubuntu';">
                  Оставить заявку
                </div>
              </div>
              <form id="form501142876" name='form501142876' role="form" action='' method='POST' data-formactiontype="2"
                    data-inputbox=".t-input-group" class="t-form js-form-proccess t-form_inputs-total_4 "
                    data-success-callback="t702_onSuccess">

                <form-component name="&quot;Почему агентства выбирают&quot;"/>
              </form>
            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="rec501142925" class="r t-rec" style=" " data-animationappear="off" data-record-type="702"><!-- T702 -->
      <div class="t702">
        <div class="t-popup" data-tooltip-hook="#popup:myform3"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
             aria-label="Оставить заявку">
          <div class="t-popup__container t-width t-width_6" style="background-color:#ffffff;">
            <div class="t702__wrapper">
              <div class="t702__text-wrapper t-align_center">
                <div class="t702__title t-title t-title_xxs" style="color:#353534;font-size:40px;font-family:'Ubuntu';">
                  Оставить заявку
                </div>
              </div>
              <form id="form501142925" name='form501142925' role="form" action='' method='POST' data-formactiontype="2"
                    data-inputbox=".t-input-group" class="t-form js-form-proccess t-form_inputs-total_4 "
                    data-success-callback="t702_onSuccess">
                <form-component name="&quot;Почему владельцы рекламных поверхностей подключаются&quot;"/>
              </form>
            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="rec502588709" class="r t-rec" style=" " data-animationappear="off" data-record-type="702"><!-- T702 -->
      <div class="t702">
        <div class="t-popup" data-tooltip-hook="#popup:myform4"
             role="dialog"
             aria-modal="true"
             tabindex="-1"
             aria-label="Оставить заявку">
          <div class="t-popup__container t-width t-width_6" style="background-color:#ffffff;">
            <div class="t702__wrapper">
              <div class="t702__text-wrapper t-align_center">
                <div class="t702__title t-title t-title_xxs" style="color:#353534;font-size:40px;font-family:'Ubuntu';">
                  Оставить заявку
                </div>
              </div>
              <div id="form502588709" name='form502588709' role="form" action='' method='POST' data-formactiontype="2"
                   data-inputbox=".t-input-group" class="t-form js-form-proccess t-form_inputs-total_4 "
                   data-success-callback="t702_onSuccess">
                <form-component name="Форма подвал"/>
              </div>
            </div>
          </div>
          <div class="t-popup__close t-popup__block-close">
            <button type="button" class="t-popup__close-wrapper t-popup__block-close-button"
                    aria-label="Закрыть диалог">
              <svg role="presentation" class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                  <rect
                      transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                      x="10.3137085" y="-3.6862915" width="2" height="30"></rect>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>


    </div>
    <div id="rec493767065" class="r t-rec" style=" " data-record-type="215"><a name="partners" style="font-size:0;"></a>
    </div>
    <div id="rec468753640" class="r t-rec t-rec_pt_0 t-rec_pb_60" style="padding-top:0px;padding-bottom:60px; "
         data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468753640" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="151" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="101"
        >
          <div class="t396__carrier" data-artboard-recid="468753640"></div>
          <div class="t396__filter" data-artboard-recid="468753640"></div>
          <div class='t396__elem tn-elem tn-elem__4687536401657721103496' data-elem-id='1657721103496'
               data-elem-type='text' data-field-top-value="101" data-field-left-value="-4" data-field-width-value="703"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="40" data-field-left-res-320-value="20"
               data-field-width-res-320-value="693" data-field-top-res-480-value="103"
               data-field-left-res-480-value="11"
               data-field-top-res-640-value="101" data-field-left-res-640-value="11"
          ><h2 class='tn-atom' field='tn_text_1657721103496'>НАШИ ПАРТНЕРЫ</h2></div>
          <div class='t396__elem tn-elem tn-elem__4687536401657721103586' data-elem-id='1657721103586'
               data-elem-type='image' data-field-top-value="519" data-field-left-value="1243"
               data-field-width-value="34"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="36" data-field-fileheight-value="71"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6133-6533-4639-a666-643530346264/xcv.svg'
                                      imgfield='tn_img_1657721103586'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468754013" class="r t-rec t-rec_pt_0 t-rec_pb_30" style="padding-top:0px;padding-bottom:30px; "
         data-record-type="595"><!-- t595 -->
      <div class="t595">
        <div class="t595__container t-card__container t-container">
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_1 " style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild6536-3039-4266-b532-613363343530/2Russ_1.svg"
                                                                 imgfield="li_img__1481203544538"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_2 t595__line_vertical_2order"
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild3235-6262-4333-b363-353464343330/3Maer_1.svg"
                                                                 imgfield="li_img__1481205129244"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal_mob2col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_3 t595__line_vertical_3order "
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/sunlight.png"
                                                                 imgfield="li_img__1481205877302"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal_mob3col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_0 t595__line_vertical_2order"
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/rasvero.png"
                                                                 imgfield="li_img__1481205139575"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal" style="background: #dbdbdb;"></div>
          <div class="t-clear t595__line t595__line_horizontal_mob2col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_1 " style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild6566-3661-4135-b464-623531343038/Group-3_3.svg"
                                                                 imgfield="li_img__1481205879887"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div
                class="t595__line t595__line_vertical t595__line_vertical_2 t595__line_vertical_3order t595__line_vertical_2order"
                style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/prime.svg"
                                                                 imgfield="li_img__1481205874086"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal_mob3col" style="background: #dbdbdb;"></div>
          <div class="t-clear t595__line t595__line_horizontal_mob2col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_3 " style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/SVG_logo_hat_trick.svg"
                                                                 imgfield="li_img__1481205138080"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_0 t595__line_vertical_2order"
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/rgb_graphics.svg"
                                                                 imgfield="li_img__1481205160378"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal" style="background: #dbdbdb;"></div>
          <div class="t-clear t595__line t595__line_horizontal_mob2col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_1 t595__line_vertical_3order "
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/fullhd.city.png"
                                                                 imgfield="li_img__1658853531682"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal_mob3col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_2 t595__line_vertical_2order"
                 style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild6461-6563-4061-b736-656461616462/5__1.svg"
                                                                 imgfield="li_img__1658853551934"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-clear t595__line t595__line_horizontal_mob2col" style="background: #dbdbdb;"></div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div class="t595__line t595__line_vertical t595__line_vertical_3 " style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild6463-3461-4430-b736-633165653261/Group-1_1.svg"
                                                                 imgfield="li_img__1658853538995"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
          <div class="t-col t595__item t595__item_4-in-row">
            <div
                class="t595__line t595__line_vertical t595__line_vertical_0 t595__line_vertical_3order t595__line_vertical_2order"
                style="background: #dbdbdb;"></div>
            <div class="t-card__col t-card__col_withoutbtn"><img class="t595__img t-img "
                                                                 src="images/tild3364-3065-4534-a435-363930623639/9_2.svg"
                                                                 imgfield="li_img__1658853524364"
                                                                 style="max-width:160px;" alt=""></div>
          </div>
        </div>
      </div>

    </div>
    <div id="rec480374466" class="r t-rec t-rec_pt_0 t-rec_pb_0" style="padding-top:0px;padding-bottom:0px; "
         data-animationappear="off" data-record-type="396"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="480374466" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="191" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="91"
        >
          <div class="t396__carrier" data-artboard-recid="480374466"></div>
          <div class="t396__filter" data-artboard-recid="480374466"></div>
          <div class='t396__elem tn-elem tn-elem__4803744661657721103496' data-elem-id='1657721103496'
               data-elem-type='text' data-field-top-value="0" data-field-left-value="305" data-field-width-value="590"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-top-res-320-value="0" data-field-left-res-320-value="33"
               data-field-width-res-320-value="255" data-field-top-res-480-value="0" data-field-left-res-480-value="25"
               data-field-width-res-480-value="439" data-field-top-res-640-value="0" data-field-left-res-640-value="25"
               data-field-top-res-960-value="0" data-field-left-res-960-value="185"
          >
            <div class='tn-atom' field='tn_text_1657721103496'>И ЕЩЕ ДЕСЯТКИ ОПЕРАТОРОВ ПО ВСЕЙ СТРАНЕ!</div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4803744661657721103586' data-elem-id='1657721103586'
               data-elem-type='image' data-field-top-value="519" data-field-left-value="1243"
               data-field-width-value="34"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="36" data-field-fileheight-value="71"
          >
            <div class='tn-atom'><img class='tn-atom__img t-img'
                                      data-original='images/tild6133-6533-4639-a666-643530346264/xcv.svg'
                                      imgfield='tn_img_1657721103586'></div>
          </div>
        </div>
      </div>
    </div>
    <div id="rec468757920" class="r t-rec" style="background-color:#111111; " data-animationappear="off"
         data-record-type="396" data-bg-color="#111111"><!-- T396 -->

      <div class='t396'>
        <div class="t396__artboard" data-artboard-recid="468757920" data-artboard-screens="320,480,640,960,1200"
             data-artboard-height="120" data-artboard-valign="center" data-artboard-upscale="grid"
             data-artboard-height-res-320="200" data-artboard-height-res-480="200" data-artboard-height-res-640="200"
             data-artboard-height-res-960="120"
        >
          <div class="t396__carrier" data-artboard-recid="468757920"></div>
          <div class="t396__filter" data-artboard-recid="468757920"></div>
          <div class='t396__elem tn-elem tn-elem__4687579201475160204612' data-elem-id='1475160204612'
               data-elem-type='image' data-field-top-value="24" data-field-left-value="717" data-field-width-value="52"
               data-field-axisy-value="top" data-field-axisx-value="left" data-field-container-value="grid"
               data-field-topunits-value="px" data-field-leftunits-value="px" data-field-heightunits-value=""
               data-field-widthunits-value="px" data-field-filewidth-value="99" data-field-fileheight-value="99"
               data-field-top-res-320-value="139" data-field-left-res-320-value="192"
               data-field-width-res-320-value="35"
               data-field-top-res-480-value="147" data-field-left-res-480-value="170"
               data-field-width-res-480-value="35"
               data-field-top-res-640-value="145" data-field-left-res-640-value="410"
               data-field-width-res-640-value="35"
               data-field-top-res-960-value="24" data-field-left-res-960-value="481"
          ><a class='tn-atom' href="https://t.me/allbillboards" target="_blank"><img class='tn-atom__img t-img'
                                                                                     data-original='images/tild6230-6234-4730-b662-663331643463/sn_telegram_1.svg'
                                                                                     imgfield='tn_img_1475160204612'></a>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201657729245548' data-elem-id='1657729245548'
               data-elem-type='shape' data-field-top-value="22" data-field-left-value="-23" data-field-height-value="57"
               data-field-width-value="307" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="25"
               data-field-left-res-320-value="2" data-field-height-res-320-value="38"
               data-field-width-res-320-value="205"
               data-field-top-res-480-value="17" data-field-left-res-480-value="-7" data-field-height-res-480-value="38"
               data-field-width-res-480-value="205" data-field-top-res-640-value="22" data-field-left-res-640-value="-9"
               data-field-height-res-640-value="38" data-field-width-res-640-value="205"
               data-field-top-res-960-value="22"
               data-field-left-res-960-value="-28"
          ><a class='tn-atom t-bgimg' href="/"
              data-original="images/logo.png"
              style="background-image:url('images/logo.png');"
          ></a></div>
          <div class='t396__elem tn-elem tn-elem__4687579201657729505715' data-elem-id='1657729505715'
               data-elem-type='shape' data-field-top-value="0" data-field-left-value="-120"
               data-field-height-value="120"
               data-field-width-value="1440" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-960-value="914"
               data-field-left-res-960-value="-256"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild6534-3836-4263-a132-623061616636/Rectangle_6.svg"
                 style="background-image:url('images/tild6534-3836-4263-a132-623061616636/Rectangle_6.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201663852521287' data-elem-id='1663852521287'
               data-elem-type='shape' data-field-top-value="559" data-field-left-value="-158"
               data-field-height-value="120" data-field-width-value="1025" data-field-axisy-value="top"
               data-field-axisx-value="left" data-field-container-value="grid" data-field-topunits-value="px"
               data-field-leftunits-value="px" data-field-heightunits-value="px" data-field-widthunits-value="px"
               data-field-top-res-640-value="1154" data-field-left-res-640-value="-68"
               data-field-height-res-640-value="265" data-field-width-res-640-value="1025"
               data-field-top-res-960-value="0" data-field-left-res-960-value="-32"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3537-3531-4538-b262-646632313766/Rectangle_24.svg"
                 style="background-image:url('images/tild3537-3531-4538-b262-646632313766/Rectangle_24.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201663852846298' data-elem-id='1663852846298'
               data-elem-type='shape' data-field-top-value="755" data-field-left-value="-60"
               data-field-height-value="120"
               data-field-width-value="1025" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-480-value="614"
               data-field-left-res-480-value="0" data-field-height-res-480-value="265"
               data-field-width-res-480-value="480" data-field-top-res-640-value="0" data-field-left-res-640-value="-29"
               data-field-height-res-640-value="200" data-field-width-res-640-value="701"
               data-field-top-res-960-value="1086" data-field-left-res-960-value="-44"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3965-6336-4166-b766-613861373039/Rectangle_25.svg"
                 style="background-image:url('images/tild3965-6336-4166-b766-613861373039/Rectangle_25.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201663853003768' data-elem-id='1663853003768'
               data-elem-type='shape' data-field-top-value="755" data-field-left-value="-60"
               data-field-height-value="120"
               data-field-width-value="1025" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="0"
               data-field-left-res-320-value="-80" data-field-height-res-320-value="200"
               data-field-width-res-320-value="505" data-field-top-res-480-value="0" data-field-left-res-480-value="-12"
               data-field-height-res-480-value="200" data-field-width-res-480-value="505"
               data-field-top-res-640-value="1397" data-field-left-res-640-value="-282"
               data-field-height-res-640-value="265" data-field-width-res-640-value="640"
               data-field-top-res-960-value="1086" data-field-left-res-960-value="-44"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3934-3034-4866-b637-666665393965/Rectangle_26.svg"
                 style="background-image:url('images/tild3934-3034-4866-b637-666665393965/Rectangle_26.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201663854229021' data-elem-id='1663854229021'
               data-elem-type='shape' data-field-top-value="755" data-field-left-value="-60"
               data-field-height-value="120"
               data-field-width-value="1025" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="px" data-field-widthunits-value="px" data-field-top-res-320-value="523"
               data-field-left-res-320-value="-191" data-field-width-res-320-value="320"
               data-field-top-res-480-value="1112" data-field-left-res-480-value="-656"
               data-field-height-res-480-value="265" data-field-width-res-480-value="480"
               data-field-top-res-640-value="1165" data-field-left-res-640-value="-510"
               data-field-height-res-640-value="265" data-field-width-res-640-value="640"
               data-field-top-res-960-value="1086" data-field-left-res-960-value="-44"
          >
            <div class='tn-atom t-bgimg'
                 data-original="images/tild3630-6432-4733-a563-386430633661/Rectangle_27.svg"
                 style="background-image:url('images/tild3630-6432-4733-a563-386430633661/Rectangle_27.svg');"
            ></div>
          </div>
          <div class='t396__elem tn-elem tn-elem__4687579201657729291393' data-elem-id='1657729291393'
               data-elem-type='button' data-field-top-value="24" data-field-left-value="1011"
               data-field-height-value="52"
               data-field-width-value="191" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="" data-field-top-res-320-value="134"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="147" data-field-top-res-480-value="142" data-field-left-res-480-value="0"
               data-field-height-res-480-value="40" data-field-width-res-480-value="147"
               data-field-top-res-640-value="140" data-field-left-res-640-value="481"
               data-field-height-res-640-value="40"
               data-field-width-res-640-value="147" data-field-top-res-960-value="24"
               data-field-left-res-960-value="775"
          ><a class='tn-atom' href="#popup:myform4">Оставить заявку</a></div>
          <div class='t396__elem tn-elem tn-elem__4687579201657729291386' data-elem-id='1657729291386'
               data-elem-type='button' data-field-top-value="24" data-field-left-value="791"
               data-field-height-value="52"
               data-field-width-value="209" data-field-axisy-value="top" data-field-axisx-value="left"
               data-field-container-value="grid" data-field-topunits-value="px" data-field-leftunits-value="px"
               data-field-heightunits-value="" data-field-widthunits-value="" data-field-top-res-320-value="79"
               data-field-left-res-320-value="20" data-field-height-res-320-value="40"
               data-field-width-res-320-value="161" data-field-top-res-480-value="71" data-field-left-res-480-value="0"
               data-field-height-res-480-value="48" data-field-width-res-480-value="161"
               data-field-top-res-640-value="76"
               data-field-left-res-640-value="467" data-field-height-res-640-value="48"
               data-field-width-res-640-value="161" data-field-top-res-960-value="24"
               data-field-left-res-960-value="555"
          ><a class='tn-atom' href="tel:+78002500630">8 800 250-06-30</a></div>
        </div>
      </div>
    </div>
    <div id="rec526594352" class="r t-rec" style=" " data-animationappear="off" data-record-type="131"><!-- T123 -->
      <div class="t123">
        <div class="t-container_100 ">
          <div class="t-width t-width_100 ">


          </div>
        </div>
      </div>
    </div>
    <div id="rec501146051" class="r t-rec" style=" " data-record-type="270">
      <div class="t270"></div>

    </div>
  </div><!--/allrecords--><!-- Stat -->
</template>

<script>
import FormComponent from "../public/components/forms/Form";

export default {
  name: 'App',
  components: { FormComponent },
}
</script>

<style>
.ant-affix {
  z-index: 100!important;
}
</style>